import { useState } from 'react';
import { updateEmail } from 'firebase/auth';
import { updateEmployee } from '../api/employeeCalls';
import { auth } from '../firebase';

import SuccessModal from '../components/SuccessModal';
import { classNames } from '../utils/classUtil';

export default function ChangeEmail(props) {
  const [show, setShow] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [repeatEmail, setRepeatEmail] = useState('');
  const [emailError, setEmailError] = useState(null);

  const handleClose = () => setShow(false);
  const handleSubmit = () => {
    if (newEmail !== repeatEmail) {
      setEmailError('Emails stimmen nicht überein');
    } else if (newEmail === '') {
      setEmailError('Leere Felder werden nicht akzeptiert');
    } else {
      // write employee data
      updateEmail(auth.currentUser, newEmail)
        .then(() => updateEmployee({ ...props.employee, email: newEmail }, true))
        .then(() => setShow(true))
        .then(() => props.onChange())
        .then(() => setChangeEmail(false))

        .catch((error) => {
          console.error(error);
        });

      // props.onEmailUpdate
    }
  };

  return (
    <div className="mt-10 space-y-12 xl:w-1/2 w-full">
      <div className="mt-8 bg-white shadow rounded-3xl w-full grid gap-6 px-6 items-center">
        <h3 className="mt-10 text-4xl font-bold text-primary">Benutzername</h3>

        {!changeEmail ? (
          <div className="w-full">
            <label htmlFor="current-email" className="text-sm font-medium leading-6 text-gray-900">
              Emailadresse
            </label>
            <div className="mt-2">
              <div className="flex items-center relative">
                <input
                  id="current-email"
                  name="current-email"
                  type="email"
                  value={props.employee.email}
                  className="bg-gray-200 relative w-full rounded border-0 py-3 px-4 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  readOnly
                />
              </div>
              <div className="mt-6 mb-10 flex items-center gap-x-6">
                <button
                  type="button"
                  className="rounded-md bg-primary px-12 py-3 text-sm font-semibold text-white shadow-sm  'bg-primary hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight'"
                  onClick={() => {
                    setChangeEmail(true);
                  }}
                >
                  Ändern
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="w-full">
              <label htmlFor="current-email" className="text-sm font-medium leading-6 text-gray-900">
                Neue Emailadresse
              </label>
              <div className="mt-2">
                <div className="flex items-center relative">
                  <input
                    id="current-email"
                    name="current-email"
                    type="email"
                    placeholder={props.employee.email}
                    className={classNames(
                      'bg-gray-200 relative w-full rounded border-0 py-3 px-4 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6',
                      emailError ? 'border-2 border-rose-500' : 'border-0'
                    )}
                    onChange={(e) => {
                      setNewEmail(e.target.value);
                      setEmailError(false);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="w-full  mt-2">
              <div>
                <label htmlFor="repeat-email" className="block text-sm font-medium leading-6 text-gray-900">
                  Emailadresse bestätigen
                </label>
                <div className="mt-2">
                  <div className="flex items-center relative">
                    <input
                      id="repeat-email"
                      name="repeat-email"
                      type="email"
                      value={repeatEmail}
                      className={classNames(
                        'bg-gray-200 relative w-full rounded border-0 py-3 px-4 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6',
                        emailError ? 'border-2 border-rose-500' : 'border-0'
                      )}
                      onChange={(e) => {
                        setRepeatEmail(e.target.value);
                        setEmailError(false);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {emailError ? <span className="flex mt-2 text-sm text-rose-500">{emailError}</span> : <></>}
            <div className="mt-6 mb-10 flex items-center gap-x-6">
              <button
                type="button"
                className="rounded-md bg-primary px-12 py-3 text-sm font-semibold text-white shadow-sm  'bg-primary hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight'"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Speichern
              </button>
              <button
                onClick={() => {
                  setChangeEmail(false);
                }}
                type="button"
                className="rounded-md bg-white border-2 px-12 py-3 text-sm font-semibold shadow-sm hover:bg-gray-200"
              >
                Abbrechen
              </button>
            </div>
          </div>
        )}
      </div>

      <SuccessModal title={'Emailadresse erfolgreich gewechselt'} show={show} onHide={handleClose} />
    </div>
  );
}
