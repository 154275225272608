import { useState } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';
import { classNames } from '../utils/classUtil';

// const entrys = [
//   { id: 1, name: 'Leslie Alexander', online: true },
//   // More users...
// ]

export default function ComboField({ placeholder, entrys, initialValue, onChange }) {
  const [query, setQuery] = useState('');
  const [selectedEntry, setSelectedEntry] = useState({ name: initialValue, online: true });
  const [inputVal, setInputVal] = useState(initialValue);

  const filteredEntrys =
    query === ''
      ? entrys
      : entrys.filter((value) => {
          return value.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      as="div"
      value={selectedEntry}
      onChange={(val) => {
        setSelectedEntry(val);
        onChange(val.name ?? selectedEntry.name);
        setInputVal(val.name);
      }}
    >
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
          onChange={(event) => {
            setQuery(event.target.value);
            setInputVal(event.target.value);
          }}
          value={inputVal}
          displayValue={(value) => value?.name}
          placeholder={placeholder}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredEntrys.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredEntrys.map((value) => (
              <Combobox.Option
                key={value.name}
                value={value}
                className={({ active }) =>
                  classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-primary text-white' : 'text-gray-900')
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      <span
                        className={classNames('inline-block h-2 w-2 flex-shrink-0 rounded-full', value.online ? 'bg-green-400' : 'bg-gray-200')}
                        aria-hidden="true"
                      />
                      <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>
                        {value.id} - {value.name}
                        <span className="sr-only"> is {value.online ? 'online' : 'offline'}</span>
                      </span>
                    </div>

                    {selected && (
                      <span className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-primary')}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
