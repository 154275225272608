import { db } from '../firebase';
import { collection, getDocs, updateDoc, doc, addDoc } from 'firebase/firestore';

export async function getClients() {
  const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/clients`, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    //mode: 'no-cors', // no-cors, *cors, same-origin
    //credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      Accept: '*/*',
      // 'X-CSRFToken': 'SWbfFptY4VN5NQGAxX6xBxlw2V76OnWEVhdZgkN1NU29BOqz2LFqFT3sWiwCCJaX',
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*',
    },
    //body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  const data = await response.json();
  // console.log(data);

  try {
    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/clients/`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: '*/*',
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // console.log(error);
  }
}

export async function createClient(client) {
  try {
    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/clients/`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(client), // body data type must match "Content-Type" header
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // console.log(error);
  }
}

export async function updateClient(client) {
  try {
    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/clients/${client.id}/`, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(client), // body data type must match "Content-Type" header
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // console.log(error);
  }
}

export async function deleteClient(client) {
  try {
    await fetch(`${process.env.REACT_APP_DATABASE_URL}/clients/${client.id}/`, {
      method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: 'application/json',
      },
    });
    return;
  } catch (error) {
    // console.log(error);
  }
}

//////// LEGACY FIREBASE FUNCTIONS ///////////////

export async function _getClients() {
  try {
    const querySnapshot = await getDocs(collection(db, 'clients'));

    const clients = [];
    querySnapshot.forEach((doc) => {
      const client = {
        ...doc.data(),
        id: doc.id,
      };

      clients.push(client);
    });
    return clients;
  } catch (error) {
    // console.log(error);
  }
}

export async function _createClient(client) {
  try {
    const docRef = await addDoc(collection(db, 'clients'), client);
    // console.log('Document written with ID: ', docRef.id);
  } catch (error) {
    // console.log(error);
  }
}

export async function _updateClient(client) {
  try {
    await updateDoc(doc(db, 'clients', client.id), client);
  } catch (error) {
    // console.log(error);
  }
}
