import { db } from '../firebase';
import { collection, getDocs, addDoc, updateDoc, doc } from 'firebase/firestore';

export async function getProjects() {
  try {
    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/projects/`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: '*/*',
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // console.log(error);
  }
}

export async function createProject(project) {
  try {
    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/projects/`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(project), // body data type must match "Content-Type" header
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // console.log(error);
  }
}

export async function updateProject(project) {
  try {
    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/projects/${project.id}/`, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(project), // body data type must match "Content-Type" header
    });
    const data = await response.json();
    return data; // parses JSON response into native JavaScript objects
  } catch (error) {
    // console.log(error);
  }
}

export async function deleteProject(project) {
  try {
    await fetch(`${process.env.REACT_APP_DATABASE_URL}/clients/${project.id}/`, {
      method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: 'application/json',
      },
    });
    return;
  } catch (error) {
    // console.log(error);
  }
}

//////// LEGACY FIREBASE FUNCTIONS ///////////////

export async function _getProjects() {
  try {
    const querySnapshot = await getDocs(collection(db, 'projects'));

    const projects = [];
    querySnapshot.forEach(async (doc) => {
      const project = {
        ...doc.data(),
        id: doc.id,
      };
      projects.push(project);
    });

    return projects;
  } catch (error) {
    // console.log(error);
  }
}

export async function _createProject(project) {
  try {
    await addDoc(collection(db, 'projects'), project);
  } catch (error) {
    // console.log(error);
  }
}

export async function _updateProject(project) {
  try {
    await updateDoc(doc(db, 'projects', project.id), project);
  } catch (error) {
    // console.log(error);
  }
}
