import { useState, useEffect } from 'react';
import { createTask, updateTask } from '../api/taskCalls';
import { classNames } from '../utils/classUtil';

export default function TaskModal(props) {
  const [task, setTask] = useState({ ...props.task });
  const [dataComplete, setDataComplete] = useState(false);
  const [debounceddaily_rate, setDebounceddaily_rate] = useState(null);
  const [debouncedhourly_rate, setDebouncedhourly_rate] = useState(null);

  const editMode = Object.keys(props.task).length > 0 ? true : false;

  const handleSubmit = async () => {
    if (editMode) {
      // we are in edit mode
      await updateTask(task);
    } else {
      // we are in create mode
      await createTask(task);
    }
    props.onSubmit();
  };
  const handleCancel = () => props.onCancel();

  const handleDelete = () => {
    props.onDelete();
  };
  const updateRates = (rate, isHourly) => {
    if (isHourly) {
      setTask({ ...task, hourly_rate: rate });
      if (!task.daily_rate) {
        const daily_rate = rate * 8;
        setDebounceddaily_rate(daily_rate);
      }
    } else {
      setTask({ ...task, daily_rate: rate });
      if (!task.hourly_rate) {
        const hourly_rate = Math.round(rate / 8);
        setDebouncedhourly_rate(hourly_rate);
      }
    }
  };
  useEffect(() => {
    // This is a custom debouncer to set the rates concurrently
    const timeoutId = setTimeout(() => {
      if (!task.daily_rate) {
        setTask({ ...task, daily_rate: debounceddaily_rate });
      } else if (!task.hourly_rate) {
        setTask({ ...task, hourly_rate: debouncedhourly_rate });
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [debounceddaily_rate, debouncedhourly_rate]);

  useEffect(() => {
    if (task.name && task.hourly_rate && task.daily_rate) {
      setDataComplete(true);
    } else {
      setDataComplete(false);
    }
  }, [task]);

  return (
    <div className="bg-white shadow rounded-lg w-1/2">
      <div className="my-8 mx-8 sm:p-6">
        <h3 className="text-3xl font-bold leading-6 text-primary">Tätigkeit {editMode ? 'bearbeiten' : 'anlegen'}</h3>
        <div className="mt-5 relative">
          <form>
            <div className="grid gap-6">
              <div className="w-full">
                <label htmlFor="task-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Tätigkeit
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="task-name"
                    id="task-name"
                    className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                    value={task.name ?? ''}
                    onChange={(e) => setTask({ ...task, name: e.target.value })}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="hourly-rate" className="block text-sm font-medium leading-6 text-gray-900">
                  Stundensatz
                </label>
                <div className="mt-2">
                  <input
                    id="hourly-rate"
                    name="hourly-rate"
                    type="number"
                    className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                    value={task.hourly_rate ?? ''}
                    onChange={(e) => updateRates(e.target.value, true)}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="daily-rate" className="block text-sm font-medium leading-6 text-gray-900">
                  Tagessatz
                </label>
                <div className="mt-2">
                  <input
                    id="daily-rate"
                    type="number"
                    name="daily-rate"
                    className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                    value={task.daily_rate ?? ''}
                    onChange={(e) => updateRates(e.target.value, false)}
                  />
                </div>
              </div>
              {editMode ? (
                <div className="w-fit flex gap-2 items-center cursor-pointer" onClick={handleDelete}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 18 21" fill="none">
                    <path
                      d="M1 5.5H17M7 9.5V15.5M11 9.5V15.5M2 5.5L3 17.5C3 18.0304 3.21071 18.5391 3.58579 18.9142C3.96086 19.2893 4.46957 19.5 5 19.5H13C13.5304 19.5 14.0391 19.2893 14.4142 18.9142C14.7893 18.5391 15 18.0304 15 17.5L16 5.5M6 5.5V2.5C6 2.23478 6.10536 1.98043 6.29289 1.79289C6.48043 1.60536 6.73478 1.5 7 1.5H11C11.2652 1.5 11.5196 1.60536 11.7071 1.79289C11.8946 1.98043 12 2.23478 12 2.5V5.5"
                      stroke="#354052"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Tätigkeit löschen</span>
                </div>
              ) : (
                <span></span>
              )}
            </div>

            <div className="mt-12 flex items-center gap-x-6">
              <button
                type="button"
                className={classNames(
                  'rounded-md bg-primary px-12 py-3 text-sm font-semibold text-white shadow-sm',
                  dataComplete
                    ? 'bg-primary hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight'
                    : 'bg-primaryLight'
                )}
                onClick={handleSubmit}
              >
                Speichern
              </button>
              <button
                type="button"
                className="rounded-md bg-white border-2 px-12 py-3 text-sm font-semibold shadow-sm hover:bg-gray-200"
                onClick={handleCancel}
              >
                Abbrechen
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
