export default function Imprint() {
  return (
    <div className="static 2xl:w-3/4 w-full max-h-full overflow-y-scroll">
      <div className="mx-12 mt-20 mb-16 px-4 sm:px-6 lg:px-8">
        <h1 className="lg:text-5xl sm:text-3xl font-extrabold">Impressum</h1>
        <div className="grid grid-cols-3 mt-14 xl:gap-10 sm:gap-2">
          <div className="flex flex-col gap-4">
            <h1 className="font-bold">Hauptstandort</h1>
            <p>co·dex GmbH</p>
            <p>Erlenring 25</p>
            <p>38458 Velpke</p>
            <p>Telefon: +49 1724 312342</p>
            <p>E-Mail: info@codex.de</p>
            <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE342814277</p>
          </div>
          <div className="w-fit">
            <h1 className="font-bold">Bildmaterial</h1>
            <p>© co·dex GmbH; midjourney.com, </p>
          </div>
          <div className="flex flex-col gap-4">
            <h1 className="font-bold">Rechtlicher Hinweis</h1>
            <p>
              Die Codex GmbH ist bestrebt, durch Aktualisierungen die Richtigkeit und Aktualität aller auf ihrer Website präsentierten Informationen
              und Daten sicherzustellen. Die Codex GmbH übernimmt jedoch keine Haftung oder Gewähr für etwaige Satzfehler sowie für die Richtigkeit
              und Aktualität der Eintragungen.
            </p>
            <p>
              Der Inhalt der Codex Website unterliegt dem Schutz des Urheberrechts. Vervielfältigungen und andere Formen der Verwertung von
              Informationen oder Daten, einschließlich der Verwendung von Texten, Textteilen oder Bildmaterial, erfordern, sofern nicht anders
              angegeben, die vorherige Zustimmung der Codex GmbH.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
