import { useState } from 'react';
import { deleteTask } from '../api/taskCalls';
import { classNames } from '../utils/classUtil';

export default function DeleteModal(props) {
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleSubmit = async () => {
    setButtonDisabled(true);
    await deleteTask(props.task.id);
    props.onSubmit();
  };
  const handleCancel = () => props.onCancel();

  return (
    <div className="bg-white shadow rounded-lg w-1/2">
      <div className="my-8 mx-8 sm:p-6">
        <h3 className="text-3xl font-bold leading-6 text-primary">Tätigkeit löschen?</h3>
        <div className="mt-5 relative">
          <div className="mt-12 flex items-center gap-x-6">
            <button
              type="button"
              className={classNames(
                'rounded-md bg-primary px-12 py-3 text-sm font-semibold text-white shadow-sm',
                !buttonDisabled
                  ? 'bg-primary hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight'
                  : 'bg-primaryLight'
              )}
              onClick={handleSubmit}
            >
              Löschen
            </button>
            <button
              type="button"
              className="rounded-md bg-white border-2 px-12 py-3 text-sm font-semibold shadow-sm hover:bg-gray-200"
              onClick={handleCancel}
            >
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
