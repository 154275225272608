import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LeftArrowHead } from '../images/left-arrow-head.svg';
import SuccessModal from '../components/SuccessModal';
import logo from '../images/logo.png';

const PasswordForgotten = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    sendPasswordResetEmail(auth, email).then(() => {
      setEmailSent(true);
    });
  };

  const handleGoBack = () => {
    setEmailSent(false);
    navigate('/login');
  };

  return (
    <>
      <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-2/5 space-y-10 py-8 px-12 bg-white rounded-xl shadow-lg">
          <div>
            <img className="mx-auto h-20 w-auto" src={logo} alt="co-dex GmbH" />
            <h2 className="mt-10 text-xl font-bold leading-9 tracking-tight text-gray-900">Passwort vergessen?</h2>
            <span className="text-l">Passiert den Besten. Sende dir eine E-Mail,</span>
            <br />
            <span className="text-l">mit der Du dein Passwort zurücksetzen kannst.</span>
          </div>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="rounded shadow-sm grid gap-10">
              <div>
                <label htmlFor="email-address" className="text-sm font-medium leading-6 text-gray-900">
                  Emailaddresse
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="bg-gray-200 w-full rounded border-0 py-3 px-4 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <button
              type="submit"
              className="mt-8 flex w-full justify-center rounded-md bg-primary px-3 py-3 text-sm font-semibold leading-6 text-white hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight"
            >
              Weiter
            </button>
            <div className="w-full flex justify-center items-center">
              <LeftArrowHead className="cursor-pointer" onClick={handleGoBack} />
              <span className="cursor-pointer" onClick={handleGoBack}>
                &nbsp;
              </span>
              <span className="cursor-pointer hover:underline" onClick={handleGoBack}>
                Zurück zum Login
              </span>
            </div>
          </form>
        </div>
        <SuccessModal
          title={'Wir haben dir eine Email gesendet!'}
          message={'Checke deinen Spam Ordner, falls du nach ein paar Minuten keine Email im Postfach siehst.'}
          show={emailSent}
          onHide={handleGoBack}
        />
      </div>
    </>
  );
};

export default PasswordForgotten;
