import { useState, useEffect } from 'react';
import { getEmployee } from '../api/employeeCalls';
import { auth } from '../firebase';

import ChangeEmail from '../modals/ChangeEmail';
import ChangePassword from '../modals/ChangePassword';
import LoadingBar from '../components/LoadingBar';

export default function Settings() {
  const [employee, setEmployee] = useState(null);

  useEffect(() => {
    if (auth.currentUser) {
      getEmployee(auth.currentUser.uid).then((employee) => {
        setEmployee(employee);
      });
    }
  }, [setEmployee]);

  const handleChange = () => {
    getEmployee(auth.currentUser.uid).then((employee) => {
      setEmployee(employee);
    });
  };

  return (
    <div className="mx-12 mt-20">
      {employee ? (
        <div className="mb-16 px-4 sm:px-6 lg:px-8">
          <div>
            <h1 className="lg:text-5xl sm:text-3xl font-extrabold">Einstellungen</h1>
            <h1 className="mt-2 lg:text-3xl sm:text-xl">Hier kannst du deine Accounteinstellungen ändern. </h1>
          </div>
          <ChangeEmail employee={employee} onChange={handleChange} />
          <ChangePassword />
        </div>
      ) : (
        <LoadingBar small />
      )}
    </div>
  );
}
