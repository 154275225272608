import { useEffect, useState } from 'react';
import { getWorkingTimes, updateWorkingTimesBilling } from '../api/workingTimesCalls';
import { auth } from '../firebase';
import LoadingBar from '../components/LoadingBar';
import DropdownFilter from '../components/DropdownFilter';

import { statusFilter, timeFilter } from '../utils/filterValues';
import { getClients } from '../api/clientCalls';
import { getProjects } from '../api/projectCalls';
import { getTasks } from '../api/taskCalls';
import { ArrowDownIcon } from '@heroicons/react/24/outline';
import { classNames } from '../utils/classUtil';
import * as XLSX from 'xlsx';
import { getEmployees } from '../api/employeeCalls';

export default function ExportTimes() {
  const [workingTimes, setWorkingTimes] = useState([]);
  const [startDate, setStartTime] = useState(new Date());
  const [endDate, setEndTime] = useState(new Date());
  const [client, setClient] = useState('');
  const [project, setProject] = useState('');
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [billed, setBilled] = useState(false);
  const [processingBilling, setProcessingBilling] = useState(false);

  const [clientList, setClientList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [userList, setUserList] = useState([]);

  //TODO: get employee name from user Id in working times

  useEffect(() => {
    const _user = auth.currentUser;
    if (_user) {
      getClients().then((clients) => {
        setClientList([{ id: 0, name: 'Alle Kunden' }, ...clients]);
      });
      getProjects().then((projects) => {
        setProjectList([{ id: 0, name: 'Alle Projekte' }, ...projects]);
      });
      getTasks().then((tasks) => {
        setTaskList([{ id: 0, name: 'Alle Aufgaben' }, ...tasks]);
      });
      getEmployees().then((users) => {
        setUserList([{ id: 0, name: 'Alle Mitarbeiter' }, ...users]);
      });
    }
  }, [setProjectList, setClientList]);

  function updateTimes(startDate, endDate) {
    setStartTime(startDate);
    setEndTime(endDate);
  }

  function updateBilled(billed) {
    setBilled(billed);
  }

  function updatesExportTimes() {
    getWorkingTimes(startDate, endDate, client, project, billed, selectedTasks[0]).then((times) => {
      //// console.log(times);
      times.forEach((time) => {
        // // console.log(time.user);
        // // console.log(userList, time.user);
        const user = userList.find((user) => user.id === time.user);
        if (user) {
          time.user = `${user.firstname} ${user.lastname}`;
        }
      });
      setWorkingTimes(times);
    });
  }

  function handleBilledClick() {
    setProcessingBilling(true);
    const times = [...workingTimes];
    const billing = times[0].billed ? false : true;
    let requests = times.map((workingTime) => {
      return new Promise((resolve) => {
        workingTime.billed = billing;
        updateWorkingTimesBilling(workingTime, resolve);
      });
    });
    Promise.all(requests).then(() => {
      setWorkingTimes(times);
      setProcessingBilling(false);
    });
  }

  function handleExportClick() {
    const currentDate = new Date().toISOString();
    // reference the table
    let table = document.getElementById('export-table');
    // assign table to workbook
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Arbeitszeiten');
    XLSX.writeFile(wb, `time-snap_${currentDate}.xlsx`);
  }

  return (
    <div className="mx-12 mt-20 mb-16">
      {clientList.length > 0 && projectList.length > 0 ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <h1 className="lg:text-5xl sm:text-3xl font-extrabold">Zeiten Exportieren</h1>
          <div className="mt-8 bg-white shadow rounded-lg lg:w-2/3 xl:w-1/2 grid gap-6 px-6">
            <div className="mt-10">
              <DropdownFilter title={'Zeitraum'} filter={timeFilter} callback={(val) => updateTimes(val.startDate, val.endDate)} />
            </div>
            <div>
              <DropdownFilter title={'Kunde'} filter={clientList} callback={(val) => setClient(val.name)} />
            </div>
            <div>
              <DropdownFilter title={'Projekt'} filter={projectList} callback={(val) => setProject(val.name)} />
            </div>
            <div>
              <DropdownFilter title={'Tätigkeiten'} filter={taskList} callback={(val) => setSelectedTasks([val.name])} />
            </div>
            <div>
              <DropdownFilter title={'Status'} filter={statusFilter} callback={(val) => updateBilled(val.name)} />
            </div>
            <div className="mt-8 mb-8">
              <button
                onClick={updatesExportTimes}
                className="block rounded-md bg-primary w-60 py-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight"
              >
                Zeiten suchen
              </button>
            </div>
          </div>

          <div className="flex justify-end mt-10 gap-6">
            <button
              id="bill-button"
              type="button"
              disabled={workingTimes.length > 0 && !processingBilling ? false : true}
              onClick={handleBilledClick}
              className={classNames(
                'block rounded-md  w-60  py-3 text-center text-sm font-semibold text-white shadow-sm ',
                workingTimes.length > 0 && !processingBilling
                  ? 'bg-primary hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight'
                  : 'bg-gray-400'
              )}
            >
              <div className="flex gap-x-2 items-center justify-center">
                <span>{workingTimes.length > 0 && workingTimes[0].billed ? 'Abrechnung Stornieren' : 'Abrechnen'}</span>
              </div>
            </button>
            <button
              type="button"
              disabled={workingTimes.length > 0 ? false : true}
              onClick={handleExportClick}
              className={classNames(
                'block rounded-md  w-60 py-3 text-center text-sm font-semibold text-white shadow-sm ',
                workingTimes.length > 0
                  ? 'bg-primary hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight'
                  : 'bg-gray-400'
              )}
            >
              <div className="flex gap-x-2 items-center justify-center">
                <ArrowDownIcon className="text-white h-4 w-4" aria-hidden="true" />
                <span>Herunterladen</span>
              </div>
            </button>
          </div>

          {workingTimes.length > 0 ? (
            <div className="mt-8 flow-root bg-white px-4 py-5 shadow rounded-3xl overflow-scroll max-h-96">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table id="export-table" className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Datum
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Kunde
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Tätigkeit
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Projekt
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Status
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Dauer
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Mitarbeiter
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {workingTimes.map((workingTime) => (
                      <tr key={workingTime.id}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{workingTime.date}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{workingTime.client}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{workingTime.task}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{workingTime.project}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{workingTime.billed ? 'Abgerechnet' : 'Offen'}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{workingTime.duration}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{workingTime.user}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="mt-8 bg-white shadow rounded-3xl w-full h-80 grid gap-6 px-6 items-center">
              <div className="ml-16 w-96">
                <h1 className="text-3xl font-extrabold text-gray-300">Welche Zeiten suchst du?</h1>
                <p className="mt-8 text-gray-300">Gebe Informationen in die Suchfelder ein, um hier deine Zeiten zu exportieren.</p>
              </div>
            </div>
          )}
        </div>
      ) : (
        <LoadingBar small />
      )}
    </div>
  );
}
