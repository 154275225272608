import { useEffect, useState } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css'; // Import Flatpickr styles
import { German } from 'flatpickr/dist/l10n/de.js';

export default function DateField({ initialDate, onChange }) {
  // console.log( initialDate );
  if (initialDate == null) {
    initialDate = new Date().toISOString().split('T')[0];
    onChange(initialDate);
  }

  // Use state to manage the selected date
  const [selectedDate, setSelectedDate] = useState(initialDate);

  // Handle input change
  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
    onChange(newDate);
  };

  useEffect(() => {
    // Initialize Flatpickr
    const flatpickrInstance = flatpickr('#date', {
      dateFormat: 'd.m.Y',
      locale: German, // Set the desired localization
      defaultDate: selectedDate,
      static: true,
      onChange: (selectedDates) => {
        if (selectedDates && selectedDates[0]) {
          const selectedDate = selectedDates[0];
          const year = selectedDate.getFullYear();
          const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
          const day = String(selectedDate.getDate()).padStart(2, '0');
          const formattedDate = `${year}-${month}-${day}`;
          onChange(formattedDate);
        }
      },
    });

    return () => {
      // Destroy the Flatpickr instance on unmount
      flatpickrInstance.destroy();
    };
  }, [initialDate, onChange]);

  return (
    <div>
      <div className="mt-2 w-full grid">
        <input
          type="date"
          id="date"
          className="block w-full rounded-md border-0 py-2.5 px-4 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
          value={selectedDate}
          onChange={handleDateChange}
        />
      </div>
    </div>
  );
}
