import React, { useState, useEffect, useRef } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/themes/light.css'; // Optional: Choose a theme
import { classNames } from '../utils/classUtil';

export default function TimeField({ id, placeholder, value: initialValue, onChange, error }) {
  const inputRef = useRef(null);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const flatpickrInstance = flatpickr(inputRef.current, {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
      defaultDate: value,
      autoFillDefaultTime: true,
      time_24hr: true,
      static: true,
      onChange: (dateStr) => {
        setValue(dateStr);
        onChange(inputRef.current.value);
      },
    });
    return () => {
      flatpickrInstance.destroy();
    };
  }, [value, onChange]);

  return (
    <div>
      <div className="mt-2 ">
        <input
          type="text"
          id={id}
          placeholder={placeholder}
          className={classNames(
            'block w-full rounded-md py-2.5 px-4 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200',
            error ? 'border-2 border-rose-500' : 'border-0'
          )}
          ref={inputRef}
        />
      </div>
    </div>
  );
}
