const date = new Date();
const startDateMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0)
const startDateLastMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
const endDateLastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
const startDateLast30Days = new Date(new Date().setDate(date.getDate() - 30));
const startDayYear = new Date(date.getFullYear(), 0, 1);
const endDateNow = new Date();
const endOfYear = new Date(new Date().getFullYear(), 11, 31, 23, 59, 59, 999);
const endOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59, 999);

export const timeFilter = [
  { id: 1, name: 'Dieser Monat', startDate: startDateMonth, endDate: endOfMonth },
  { id: 2, name: 'Letzter Monat', startDate: startDateLastMonth, endDate: endDateLastMonth },
  { id: 3, name: 'Letzte 30 Tage', startDate: startDateLast30Days, endDate: endDateNow },
  { id: 4, name: 'Dieses Jahr', startDate: startDayYear, endDate: endOfYear },
];

export const statusFilter = [
  { id: 1, name: 'Alle Status' },
  { id: 2, name: 'Offen' },
  { id: 3, name: 'Abgerechnet' },
];
