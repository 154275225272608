import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { writeTimeData } from '../api/workingTimesCalls';

import DateField from '../components/DateField';
import TimeField from '../components/TimeField';
import ComboField from '../components/ComboField';
import SuccessModal from '../components/SuccessModal';
import { getTimeDifference } from '../utils/timeUtils';
import WorkTime from '../models/WorkTime';

import { classNames } from '../utils/classUtil';

const AddTime = (props) => {
  const [show, setShow] = useState(false);
  const [worktime, setWorktime] = useState(new WorkTime());
  const [timesUnfit, setTimesUnfit] = useState(false);
  const [dataComplete, setDataComplete] = useState(false);
  
  const initialDate = new Date()
  const handleSubmit = async function () {
    setDataComplete(false);

    await writeTimeData(worktime);
    // // console.log('written data!');
    setDataComplete(true);

    props.onAdded(worktime.start, worktime.end);

    // TODO: clear form input!
    setShow(true);
    //// console.log('modal', show)
  };

  useEffect(() => {
    const _user = auth.currentUser;
    if (_user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      worktime.user = _user;

      //const durationField = document.getElementById('durationInput'); // Change to the actual ID of your duration input field
      if (worktime.end !== undefined && worktime.start !== undefined) {
        if (worktime.end <= worktime.start) {
          // we throw an error that lets the user know the times are not acceptable
          setTimesUnfit(true);
        } else {
          setTimesUnfit(false);
          const duration = getTimeDifference(worktime.start, worktime.end);
          if (duration !== worktime.duration) {
            setWorktime({ ...worktime, duration: duration });
          }
        }
      }

      if (worktime.start && worktime.end && worktime.client && worktime.project && worktime.task) {
        setDataComplete(true);
      } else {
        setDataComplete(false);
      }
    }
  }, [worktime]);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div className="bg-white shadow rounded-lg w-full ">
      <div className="my-8 mx-8 py-14 px-6">
        <h3 className="text-3xl font-bold leading-6 text-primary">Zeit hinzufügen</h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>Hier kannst du deine Arbeitszeiten festhalten</p>
        </div>
        <div className="grid gap-8 mt-5 relative">
          {!props.disableFlatPickr ? (
            <DateField initialDate={initialDate} onChange={(val) => (worktime.date = val)} />
          ) : (
            // we need to do this to properly to destroy the flatpickr instance
            <div className="block w-full mt-2 rounded-md text-gray-900 border-0 py-2.5 px-4 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200 ">
              <span>{worktime.date}</span>
            </div>
          )}

          <div className="flex flex-col">
            <div className="flex w-full justify-between items-center gap-2">
              <TimeField
                id="startTimeInput"
                placeholder="Start"
                value={worktime.start || ''}
                onChange={(val) => {
                  setWorktime({ ...worktime, start: val });
                }}
                error={timesUnfit}
              />
              <span className="text-2xl">-</span>
              <TimeField
                id="endTimeInput"
                placeholder="Ende"
                value={worktime.end || ''}
                onChange={(val) => {
                  setWorktime({ ...worktime, end: val });
                }}
                error={timesUnfit}
              />
            </div>
            {timesUnfit ? <span className="absolute text-sm mt-16 text-rose-500">Die Endzeit kann nicht vor der Startzeit liegen</span> : <></>}
          </div>
          <ComboField placeholder={'Kunde'} entrys={props.clients} onChange={(val) => setWorktime({ ...worktime, client: val })} />
          <ComboField placeholder={'Projekt'} entrys={props.projects} onChange={(val) => setWorktime({ ...worktime, project: val })} />
          <ComboField placeholder={'Tätigkeit'} entrys={props.tasks} onChange={(val) => setWorktime({ ...worktime, task: val })} />
        </div>
        <div className="mt-10 flex items-center gap-x-6">
          {/* <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={handleCancel}>
              Abbrechen TODO: this could become a CLEAR FORM button
            </button> */}
          <button
            id="time-submit"
            disabled={timesUnfit || !dataComplete}
            type="button"
            onClick={handleSubmit}
            className={classNames(
              'rounded-md bg-primary px-20 py-3 text-sm font-semibold text-white shadow-sm',
              !timesUnfit && dataComplete
                ? 'bg-primary hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight'
                : 'bg-primaryLight'
            )}
          >
            Hinzufügen
          </button>
        </div>
      </div>
      <SuccessModal title={'Arbeitszeit erfolgreich angelegt'} show={show} onHide={handleClose} />
    </div>
  );
};

export default AddTime;
