// import React, {useState, useEffect} from 'react';
import './App.css';
import Login from './page/Login';
import PasswordForgotten from './page/PasswordForgotten';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import SidebarNavigation from './components/Navigation';
import LoadingBar from './components/LoadingBar';
import Clients from './page/Clients';
import Projects from './page/Projects';
import Employees from './page/Employees';
import Settings from './page/Settings';
import Home from './page/Home';
import PageNotFound from './page/PageNotFound';
import Tasks from './page/Tasks';
import Imprint from './page/Imprint';
import DataProtection from './page/DataProtection';

import { useState, useEffect, useRef } from 'react';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import ExportTimes from './page/ExportTimes';

function RenderItem({ loggedIn, loginStatusResolved }) {
  const divRef = useRef(null);

  const handleSignOut = () => {
    loggedIn = false;
  };

  const handleResize = (height) => {
    divRef.current.scrollTo({
      top: height,
      left: 0,
      behavior: 'smooth',
    });
  };

  if (!loggedIn && !loginStatusResolved) {
    return <LoadingBar />;
  } else if (!loggedIn && loginStatusResolved) {
    return (
      <Router>
        <div className="w-full bg-gray-100">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/password-forgotten" element={<PasswordForgotten />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </Router>
    );
  } else {
    return (
      <Router>
        <div className="basis-1/6">
          <SidebarNavigation onSignOut={handleSignOut} />
        </div>
        <div ref={divRef} className="basis-5/6 overflow-scroll bg-gray-100">
          <Routes>
            <Route path="/" element={<Home onResize={handleResize} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/home" element={<Home onResize={handleResize} />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/export" element={<ExportTimes />} />
            <Route path="/tasks" element={<Tasks />} />
            <Route path="/employees" element={<Employees />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/data-protection" element={<DataProtection />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </Router>
    );
  }
}

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginStatusResolved, setLoginStatus] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, async (_user) => {
      if (_user) {
        setLoggedIn(true);
        setLoginStatus(true);
        // console.log('user is logged in!');
      } else {
        setLoggedIn(false);
        setLoginStatus(true);
        // console.log('user is not logged in');
      }
    });
  }, []);

  return (
    <div className="flex flex-row h-screen">
      <RenderItem loggedIn={loggedIn} loginStatusResolved={loginStatusResolved} />
    </div>
  );
}

export default App;
