function getTimeInMinutes(timeString) {
  const [hours, minutes] = timeString.split(':').map(Number);
  return hours * 60 + minutes;
}
export function getTimeDifference(startTime, endTime) {
  const startMinutes = getTimeInMinutes(startTime);
  const endMinutes = getTimeInMinutes(endTime);

  return formatMinutesToTime(endMinutes - startMinutes);
}
export function addDuration(startTime, duration) {
  const startMinutes = getTimeInMinutes(startTime);
  const endMinutes = startMinutes + getTimeInMinutes(duration);
  return formatMinutesToTime(endMinutes);
}
export function formatMinutesToTime(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = remainingMinutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

export function getFormattedCurrentTime() {
  const currentTime = new Date();
  const currentHours = currentTime.getHours();
  const currentMinutes = currentTime.getMinutes();

  // Format hours and minutes to two digits (e.g., 08:30)
  const formattedCurrentTime = `${currentHours.toString().padStart(2, '0')}:${currentMinutes.toString().padStart(2, '0')}`;
  return formattedCurrentTime;
}

export function extractFormattedTime(date) {
  const currentHours = date.getHours();
  const currentMinutes = date.getMinutes();

  // Format hours and minutes to two digits (e.g., 08:30)
  const formattedCurrentTime = `${currentHours.toString().padStart(2, '0')}:${currentMinutes.toString().padStart(2, '0')}`;
  return formattedCurrentTime;
}
