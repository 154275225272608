import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { classNames } from '../utils/classUtil';

export default function DropdownFilter({ title, filter, callback }) {
  const [selected, setSelected] = useState(filter[0]);
  const [bottom, setBottom] = useState(false);

  function getElementHeight() {
    let element = document.getElementById('reference-point');
    let windowHeight = window.innerHeight;
    var rect = element.getBoundingClientRect(); // find the current element's height in the window
    if (rect.top > windowHeight * 0.7) {
      // compare with the window height
      setBottom(true);
    } else {
      setBottom(false);
    }
  }

  async function onChange(selectedItem) {
    setSelected(selectedItem);
  }
  useEffect(() => {
    // every time we update our selected element, the callback will be triggered
    callback(selected);
  }, [selected]);

  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">{title}</Listbox.Label>
          <div id="reference-point" className="relative mt-2">
            <Listbox.Button
              onClick={getElementHeight}
              className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              <span className="block truncate">{selected.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options
                className={classNames(
                  'absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                  bottom ? 'bottom-full' : ''
                )}
              >
                {filter.map((selectedFilter) => (
                  <Listbox.Option
                    key={selectedFilter.id}
                    className={({ active }) =>
                      classNames(active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9')
                    }
                    value={selectedFilter}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{selectedFilter.name}</span>

                        {selected ? (
                          <span
                            className={classNames(active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4')}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
