import { classNames } from '../utils/classUtil';
import { ReactComponent as UpArrow } from '../images/upArrow.svg';
import { ReactComponent as DownArrow } from '../images/downArrow.svg';

export default function EmployeesTable(props) {
  function handleCollapsible(e) {
    let target = e.target;
    while (target.id !== 'collapsible-card') {
      // could be we clicked a child element like the text or one of the arrow svgs
      target = target.parentNode;
    }
    const content = target.nextElementSibling; // we unfold the content and also switch up the arrow svgs
    const downArrow = target.querySelector('#downArrow');
    const upArrow = target.querySelector('#upArrow');
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
      setTimeout(() => {
        downArrow.classList.remove('hidden');
        upArrow.classList.add('hidden');
      }, 100);
    } else {
      content.style.maxHeight = content.scrollHeight + 'px';
      setTimeout(() => {
        downArrow.classList.add('hidden');
        upArrow.classList.remove('hidden');
      }, 100);
    }
  }
  return (
    <>
      <div className="flex align-items-end justify-between">
        <h1 className={classNames('lg:text-5xl sm:text-3xl font-extrabold', props.archived ? 'text-gray-300' : '')}>
          {props.archived ? 'Archivierte' : 'Aktuelle'} Mitarbeiter
        </h1>

        {!props.archived ? (
          <button
            type="button"
            className="rounded-md bg-primary lg:px-20 sm:px-12 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight"
            onClick={props.onAdd}
          >
            Hinzufügen
          </button>
        ) : (
          <div></div>
        )}
      </div>
      {props.employees.length > 0 ? (
        <div className="mt-8 flow-root bg-white sm:px-6 lg:px-8 py-2 shadow rounded-3xl overflow-scroll ">
          {props.employees.map((employee, index) => (
            <div key={employee.id} className="mt-4">
              <div id="collapsible-card" className="collapsible cursor-pointer w-full flex justify-between" onClick={handleCollapsible}>
                <span className="font-bold text-lg">
                  {employee.firstname} {employee.lastname}
                </span>
                <DownArrow id="downArrow" />
                <UpArrow id="upArrow" className="hidden" />
              </div>
              <div className="collapsible-content" id="collapsible-content">
                <span className="font-bold mt-2 text-gray-300 text-l">Personalinfos</span>
                <p className="mt-2 text-sm">{employee.position}</p>
                <p className="mt-2 text-sm">{employee.email}</p>
                {employee.weekly_hours ? <p className="mt-2 text-sm">{employee.weekly_hours} Arbeitsstunden pro Woche</p> : <span></span>}
                {employee.monthly_hours ? <p className="mt-2 text-sm">{employee.monthly_hours} Arbeitsstunden pro Monat</p> : <span></span>}

                <div className="py-6">
                  <span className="font-bold py-4 text-gray-300 text-l">Honorarangaben</span>
                  <div className="flex justify-between gap-20 items-end">
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 sm:pl-0">
                            <span className="sr-only">Basis</span>
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 sm:pl-0">
                            Intern
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                            Extern
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        <tr>
                          <td className="whitespace-nowrap  px-3 py-4 text-sm font-bold">Stundensatz</td>
                          <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">{employee.internal_hourly_rate}€</td>
                          <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">{employee.external_hourly_rate}€</td>
                        </tr>
                        <tr>
                          <td className="whitespace-nowrap  px-3 py-4 text-sm font-bold">Tagessatz</td>
                          <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">{employee.internal_daily_rate}€</td>
                          <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">{employee.external_daily_rate}€ </td>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      className="h-12 rounded-md bg-white border-2 px-10 py-3 text-sm font-semibold shadow-sm hover:bg-gray-200"
                      onClick={() => props.onEdit(employee)}
                    >
                      Bearbeiten
                    </button>
                  </div>
                </div>
              </div>
              {index < props.employees.length - 1 ? <hr className="mt-4" /> : <div className="mt-4"></div>}
            </div>
          ))}
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}
