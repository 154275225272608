import { useState } from 'react';
import { updatePassword } from 'firebase/auth';
import { auth } from '../firebase';

import SuccessModal from '../components/SuccessModal';
import { ReactComponent as HidePassword } from '../images/hide-password-icon.svg';
import { ReactComponent as ShowPassword } from '../images/show-password-icon.svg';
import { classNames } from '../utils/classUtil';

export default function ChangePassword() {
  const [show, setShow] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [newPasswordVisible, toggleNewPasswordVisible] = useState(false);
  const [passwordCommitVisible, toggleCommitPasswordVisible] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);

  const handleClose = () => setShow(false);

  // Function to handle form submission
  const handleSubmit = () => {
    if (newPassword !== repeatPassword) {
      setPasswordError('Passwörter stimmen nicht überein');
    } else if (newPassword === '') {
      setPasswordError('Leere Felder werden nicht akzeptiert');
    } else {
      updatePassword(auth.currentUser, newPassword)
        .then(() => setShow(true))
        .then(() => setChangePassword(false))
        .catch((error) => {
          console.error(error);
        });
      // Perform further actions, such as submitting the form
    }
  };

  return (
    <div className="mt-20 space-y-12 xl:w-1/2 w-full">
      <div className="mt-8 bg-white shadow rounded-3xl w-full grid gap-6 px-6 items-center">
        <h3 className="mt-10 text-4xl font-bold text-primary">Passwort</h3>

        {!changePassword ? (
          <div className="w-full">
            <div className="mt-2">
              <div className=" mb-10 flex items-center gap-x-6">
                <button
                  type="button"
                  className="rounded-md bg-primary px-12 py-3 text-sm font-semibold text-white shadow-sm  'bg-primary hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight'"
                  onClick={() => {
                    setChangePassword(true);
                  }}
                >
                  Ändern
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="w-full">
              <label htmlFor="current-password" className="text-sm font-medium leading-6 text-gray-900">
                Neue Passwordadresse
              </label>
              <div className="mt-2">
                <div className="flex items-center relative">
                  <input
                    id="current-password"
                    name="current-password"
                    type={newPasswordVisible ? 'text' : 'password'}
                    className={classNames(
                      'bg-gray-200 relative w-full rounded border-0 py-3 px-4 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6',
                      passwordError ? 'border-2 border-rose-500' : 'border-0'
                    )}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setPasswordError(null);
                    }}
                  />
                  <ShowPassword
                    onClick={() => toggleNewPasswordVisible(false)}
                    className={classNames('absolute cursor-pointer right-2 z-10', newPasswordVisible ? '' : 'hidden')}
                  />
                  <HidePassword
                    onClick={() => toggleNewPasswordVisible(true)}
                    className={classNames('absolute cursor-pointer right-2 z-10', newPasswordVisible ? 'hidden' : '')}
                  />
                </div>
              </div>
            </div>

            <div className="w-full mt-2">
              <div>
                <label htmlFor="repeat-password" className="block text-sm font-medium leading-6 text-gray-900">
                  Passwordadresse bestätigen
                </label>
                <div className="mt-2">
                  <div className="flex items-center relative">
                    <input
                      id="repeat-password"
                      name="repeat-password"
                      type={passwordCommitVisible ? 'text' : 'password'}
                      value={repeatPassword}
                      className={classNames(
                        'bg-gray-200 relative w-full rounded border-0 py-3 px-4 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6',
                        passwordError ? 'border-2 border-rose-500' : 'border-0'
                      )}
                      onChange={(e) => {
                        setRepeatPassword(e.target.value);
                        setPasswordError(null);
                      }}
                    />
                    <ShowPassword
                      onClick={() => toggleCommitPasswordVisible(false)}
                      className={classNames('absolute cursor-pointer right-2 z-10', passwordCommitVisible ? '' : 'hidden')}
                    />
                    <HidePassword
                      onClick={() => toggleCommitPasswordVisible(true)}
                      className={classNames('absolute cursor-pointer right-2 z-10', passwordCommitVisible ? 'hidden' : '')}
                    />
                  </div>
                </div>
              </div>
            </div>
            {passwordError ? <span className="flex mt-2 text-sm text-rose-500">{passwordError}</span> : <></>}
            <div className="mt-6 mb-10 flex items-center gap-x-6">
              <button
                type="button"
                className="rounded-md bg-primary px-12 py-3 text-sm font-semibold text-white shadow-sm  'bg-primary hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight'"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Speichern
              </button>
              <button
                onClick={() => {
                  setChangePassword(false);
                }}
                type="button"
                className="rounded-md bg-white border-2 px-12 py-3 text-sm font-semibold shadow-sm hover:bg-gray-200"
              >
                Abbrechen
              </button>
            </div>
          </div>
        )}
      </div>

      <SuccessModal title={'Passwort erfolgreich gewechselt'} show={show} onHide={handleClose} />
    </div>
  );
}
