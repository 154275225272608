import React, { useState } from 'react';
import { signInWithEmailAndPassword, setPersistence, browserSessionPersistence, inMemoryPersistence } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo.png';
import { ReactComponent as HidePassword } from '../images/hide-password-icon.svg';
import { ReactComponent as ShowPassword } from '../images/show-password-icon.svg';
import { classNames } from '../utils/classUtil';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, togglePasswordVisible] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [remember, setRemember] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    // Set the desired persistence type based on the remember value
    const persistenceType = remember ? inMemoryPersistence : browserSessionPersistence;

    // Set the persistence type and then sign in
    setPersistence(auth, persistenceType)
      .then(() => {
        if (process.env.REACT_APP_ADMIN_LOGIN_EMAIL) {
          signInWithEmailAndPassword(auth, process.env.REACT_APP_ADMIN_LOGIN_EMAIL, process.env.REACT_APP_ADMIN_LOGIN_PW);
        } else {
          signInWithEmailAndPassword(auth, email, password).catch(() => {
            setLoginFailed(true);
          });
        }
      })
      .then(() => {
        // Navigate to the desired location
        const interval = setInterval(() => {
          if (auth.currentUser) {
            clearInterval(interval);
            navigate('/home');
          }
        }, 200);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // console.log(errorCode, errorMessage);
      });
  };

  const handlePasswordForgotton = () => {
    navigate('/password-forgotten');
  };

  return (
    <>
      <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-2/5 space-y-10 py-8 px-12 bg-white rounded-xl shadow-lg">
          <div>
            <img className="mx-auto h-20 w-auto" src={logo} alt="co-dex GmbH" />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Melde dich an, um fortzufahren</h2>
          </div>
          <form className="space-y-2" onSubmit={handleLogin}>
            <div className="rounded shadow-sm grid gap-6">
              <div>
                <label htmlFor="email-address" className="text-sm font-medium leading-6 text-gray-900">
                  Emailaddresse
                </label>
                {process.env.REACT_APP_ADMIN_LOGIN_EMAIL ? (
                  <input
                    id="email-address"
                    name="email"
                    type="password"
                    value={process.env.REACT_APP_ADMIN_LOGIN_EMAIL}
                    className={classNames(
                      'bg-gray-200 relative w-full rounded border-0 py-3 px-4 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6',
                      loginFailed ? 'border-2 border-rose-500' : 'border-0'
                    )}
                    //onChange={(e) => setEmail(e.target.value)}
                    readOnly
                  />
                ) : (
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className={classNames(
                      'bg-gray-200 relative w-full rounded border-0 py-3 px-4 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6',
                      loginFailed ? 'border-2 border-rose-500' : 'border-0'
                    )}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setLoginFailed(false);
                    }}
                  />
                )}
              </div>
              <div>
                <label htmlFor="client-name" className="text-sm font-medium leading-6 text-gray-900">
                  Passwort
                </label>
                <div className="flex items-center relative mt-2">
                  {process.env.REACT_APP_ADMIN_LOGIN_PW ? (
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="bg-gray-200 relative w-full rounded border-0 py-3 px-4 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                      readOnly
                      value={process.env.REACT_APP_ADMIN_LOGIN_PW}
                    />
                  ) : (
                    <input
                      id="password"
                      name="password"
                      type={passwordVisible ? 'text' : 'password'}
                      autoComplete="current-password"
                      required
                      className={classNames(
                        'bg-gray-200 relative w-full rounded border-0 py-3 px-4 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6',
                        loginFailed ? 'border-2 border-rose-500' : 'border-0'
                      )}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setLoginFailed(false);
                      }}
                    />
                  )}

                  <ShowPassword
                    onClick={() => togglePasswordVisible(false)}
                    className={classNames('absolute cursor-pointer right-2 z-10', passwordVisible ? '' : 'hidden')}
                  />
                  <HidePassword
                    onClick={() => {
                      if (!process.env.REACT_APP_ADMIN_LOGIN_PW) {
                        togglePasswordVisible(true);
                      }
                    }}
                    className={classNames('absolute cursor-pointer right-2 z-10', passwordVisible ? 'hidden' : '')}
                  />
                </div>
              </div>
            </div>
            {loginFailed ? <span className="flex text-sm text-rose-500">Emailaddresse und Passwort stimmen nicht überein</span> : <></>}

            <div className="flex items-center justify-between">
              <div className="mt-2 mb-4 flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  value={remember}
                  onChange={(e) => setRemember(e.target.checked)}
                  className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                />
                <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                  Eingeloggt bleiben
                </label>
              </div>
            </div>

            <div className="flex gap-8 items-center">
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight"
              >
                Einloggen
              </button>
              <div className="w-full hover:underline flex justify-center">
                <span className="cursor-pointer" onClick={handlePasswordForgotton}>
                  Password vergessen?
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
