import { useState, useEffect } from 'react';
import { getTasks } from '../api/taskCalls';
import LoadingBar from '../components/LoadingBar';
import TaskModal from '../modals/TaskModal';
import DeleteModal from '../modals/DeleteModal';
import taskImage from '../images/tasks-image.png';

export default function Tasks() {
  const [tasks, setTasks] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const editingTask = { ...selectedTask };

  function handleAddClick() {
    setShowTaskModal(true);
  }
  function handleEditClick(task) {
    setSelectedTask(task);
    setShowTaskModal(true);
  }
  function handleCancel() {
    closeAction();
  }
  function handleSubmit() {
    getTasks().then((tasks) => {
      setTasks(tasks);
    });
    closeAction();
  }
  function handleDeleteInitiation() {
    setShowTaskModal(false);
    setShowDeleteModal(true);
  }

  function closeAction() {
    setSelectedTask(null);
    setShowTaskModal(false);
    setShowDeleteModal(false);
  }
  useEffect(() => {
    getTasks().then((tasks) => {
      setTasks(tasks);
    });
  }, [setTasks]);

  return (
    <div className="static 2xl:w-3/4 w-full h-full overflow-y-scroll">
      {showTaskModal ? (
        <div className="absolute w-5/6 h-full top-0 z-50 bg-gray-500/50">
          <div className="h-full flex justify-center items-center">
            <TaskModal task={editingTask} onCancel={handleCancel} onSubmit={handleSubmit} onDelete={handleDeleteInitiation} />
          </div>
        </div>
      ) : (
        <></>
      )}
      {showDeleteModal ? (
        <div className="absolute w-5/6 h-full top-0 z-50 bg-gray-500/50">
          <div className="h-full flex justify-center items-center">
            <DeleteModal task={editingTask} onCancel={handleCancel} onSubmit={handleSubmit} />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="mx-12 mt-20 px-4 sm:px-6 lg:px-8">
        {tasks ? (
          <div className="grid">
            <div className="flex align-items-end justify-between">
              <h1 className="text-5xl font-extrabold">Tätigkeiten</h1>
              <button
                type="button"
                className="rounded-md bg-primary px-20 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight"
                onClick={handleAddClick}
              >
                Hinzufügen
              </button>
            </div>
            <div className="mt-8 flow-root bg-white px-4 py-5 shadow rounded-3xl overflow-scroll max-h-96">
              <div className="overflow-scroll">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900 sm:pl-0">
                          <span className="sr-only">Projekt</span>
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Stundensatz
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Tagessatz
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                          <span className="sr-only">Bearbeiten</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {tasks.map((task) => (
                        <tr key={task.id}>
                          <td className="whitespace-nowrap  px-3 py-4 text-sm font-bold">{task.name}</td>
                          <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">{task.hourly_rate}</td>
                          <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">{task.daily_rate}</td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                            <button className="text-primary hover:text-indigo-900" onClick={() => handleEditClick(task)}>
                              Bearbeiten
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="grid h-96 self-end">
              <img className="w-96 self-end" src={taskImage} alt="co-dex GmbH"></img>
            </div>
          </div>
        ) : (
          <LoadingBar small />
        )}
      </div>
    </div>
  );
}
