import { useState, useEffect } from 'react';
import { createClient, updateClient } from '../api/clientCalls';
import { classNames } from '../utils/classUtil';

export default function ClientModal(props) {
  const [client, setClient] = useState({ ...props.client });
  const [dataComplete, setDataComplete] = useState(false);
  const [debounceddaily_rate, setDebounceddaily_rate] = useState(null);
  const [debouncedhourly_rate, setDebouncedhourly_rate] = useState(null);

  const editMode = Object.keys(props.client).length > 0 ? true : false;

  const handleSubmit = async () => {
    setDataComplete(false);
    if (editMode) {
      // we are in edit mode
      await updateClient(client);
    } else {
      // we are in create mode
      await createClient(client);
    }
    props.onSubmit();
  };
  const handleCancel = () => props.onCancel();
  const updateRates = (rate, isHourly) => {
    rate = Number(rate);
    if (isHourly) {
      setClient({ ...client, hourly_rate: rate });
      if (!client.daily_rate) {
        const daily_rate = rate * 8;
        setDebounceddaily_rate(daily_rate);
      }
    } else {
      setClient({ ...client, daily_rate: rate });
      if (!client.hourly_rate) {
        const hourly_rate = Math.round(rate / 8);
        setDebouncedhourly_rate(hourly_rate);
      }
    }
  };
  useEffect(() => {
    // This is a custom debouncer to set the rates concurrently
    const timeoutId = setTimeout(() => {
      if (!client.daily_rate) {
        setClient({ ...client, daily_rate: debounceddaily_rate });
      } else if (!client.hourly_rate) {
        setClient({ ...client, hourly_rate: debouncedhourly_rate });
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [debounceddaily_rate, debouncedhourly_rate]);

  useEffect(() => {
    if (client.name && client.hourly_rate && client.daily_rate) {
      setDataComplete(true);
    } else {
      setDataComplete(false);
    }
  }, [client]);

  return (
    <div className="bg-white shadow rounded-lg w-1/2">
      <div className="my-8 mx-8 sm:p-6">
        <h3 className="text-3xl font-bold leading-6 text-primary">Kunde {editMode ? 'bearbeiten' : 'anlegen'}</h3>
        <div className="mt-5 relative">
          <form>
            <div className="grid gap-6">
              <div className="w-full">
                <label htmlFor="client-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Kunde
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="client-name"
                    id="client-name"
                    //autoComplete="client-name"
                    className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                    value={client.name ?? ''}
                    onChange={(e) => setClient({ ...client, name: e.target.value })}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="hourly-rate" className="block text-sm font-medium leading-6 text-gray-900">
                  Stundensatz
                </label>
                <div className="mt-2">
                  <input
                    id="hourly-rate"
                    name="hourly-rate"
                    type="number"
                    className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                    value={client.hourly_rate ?? ''}
                    onChange={(e) => updateRates(e.target.value, true)}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="daily-rate" className="block text-sm font-medium leading-6 text-gray-900">
                  Tagessatz
                </label>
                <div className="mt-2">
                  <input
                    id="daily-rate"
                    type="number"
                    name="daily-rate"
                    className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                    value={client.daily_rate ?? ''}
                    onChange={(e) => updateRates(e.target.value, false)}
                  />
                </div>
              </div>
              {editMode ? (
                <div className="flex gap-2 items-center">
                  <input
                    className="rounded border-1 border-gray-300 shadow-sm focus:ring-0"
                    type="checkbox"
                    id="archive"
                    name="archive"
                    checked={client.archived ?? false}
                    onChange={(e) => setClient({ ...client, archived: e.target.checked })}
                  />
                  <label htmlFor="archive">Kunden ins Archiv verschieben</label>
                </div>
              ) : (
                <span></span>
              )}
            </div>

            <div className="mt-12 flex items-center gap-x-6">
              <button
                type="button"
                id="submit-button"
                className={classNames(
                  'rounded-md bg-primary px-12 py-3 text-sm font-semibold text-white shadow-sm',
                  dataComplete
                    ? 'bg-primary hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight'
                    : 'bg-primaryLight'
                )}
                onClick={handleSubmit}
              >
                Speichern
              </button>
              <button
                type="button"
                className="rounded-md bg-white border-2 px-12 py-3 text-sm font-semibold shadow-sm hover:bg-gray-200"
                onClick={handleCancel}
              >
                Abbrechen
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
