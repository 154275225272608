import React, { useRef, useEffect, useState } from 'react';

import WorkingTimes from '../components/WorkingTimes';

const Home = (props) => {
  const divRef = useRef(null);
  const [currentHeight, setCurrentHeight] = useState(0);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === divRef.current) {
          if (currentHeight + 30 < entry.contentRect.height && currentHeight !== 0) {
            // we add extra 30px to not react to any small changes of div size
            const newHeight = entry.contentRect.height;
            setCurrentHeight(newHeight);
            props.onResize(newHeight);
          } else {
            const newHeight = entry.contentRect.height;
            setCurrentHeight(newHeight);
          }
        }
      }
    });
    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, [props, currentHeight]);
  return (
    <div ref={divRef} className="flex flex-row">
      <WorkingTimes />
    </div>
  );
};

export default Home;
