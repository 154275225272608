import { db } from '../firebase';
import { collection, getDocs, getDoc, setDoc, doc, updateDoc } from 'firebase/firestore';

///////////////// EMPLOYEE DATA //////////////////

export async function getEmployees() {
  try {
    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/employees/`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: '*/*',
      },
    });
    const data = await response.json();

    data.forEach((employee) => {
      employee.firstname = employee.name.split(' ')[0] || '';
      employee.lastname = employee.name.split(' ')[1] || '';
    });
    return data;
  } catch (error) {
    // console.log(error);
  }
}

export async function getEmployee(employeeId) {
  try {
    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/employees/${employeeId}/`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: '*/*',
      },
    });
    const employee = await response.json();
    employee.firstname = employee.name.split(' ')[0] || '';
    employee.lastname = employee.name.split(' ')[1] || '';
    return employee;
  } catch (error) {
    // console.log(error);
  }
}

export async function createEmployee(employee) {
  try {
    const registerResponse = await fetch('https://us-central1-time-snap.cloudfunctions.net/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(employee),
    });
    const data = await registerResponse.json();
    employee.id = String(data.uid);
    const newEmployee = await writeEmployeeData(employee);
    return data;
  } catch (error) {
    // console.log(error);
  }
}

export async function updateEmployee(employee, emailChanged) {
  try {
    if (emailChanged) {
      await fetch('https://us-central1-time-snap.cloudfunctions.net/updateUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(employee),
      });
    }

    const newEmployee = {
      ...employee,
      name: employee.firstname + ' ' + employee.lastname,
    };
    delete newEmployee['password'];

    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/employees/${employee.id}/`, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newEmployee), // body data type must match "Content-Type" header
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // console.log(error);
  }
}

export async function writeEmployeeData(employee) {
  const newEmployee = {
    ...employee,
    name: employee.firstname + ' ' + employee.lastname,
    role: 'user',
    archived: false,
  };
  delete newEmployee['password'];
  delete newEmployee['firstname'];
  delete newEmployee['lastname'];
  try {
    // console.log(newEmployee);
    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/employees/`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newEmployee), // body data type must match "Content-Type" header
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // console.log(error);
  }
}

export async function deleteEmployee(employeeId) {
  try {
    await fetch(`${process.env.REACT_APP_DATABASE_URL}/employees/${employeeId}/`, {
      method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: 'application/json',
      },
    });
    return;
  } catch (error) {
    // console.log(error);
  }
}

//////// LEGACY FIREBASE FUNCTIONS ///////////////

export async function _getEmployees() {
  try {
    const querySnapshot = await getDocs(collection(db, 'employees'));

    const employees = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const employee = {
        ...doc.data(),
        id: doc.id,
      };

      employees.push(employee);
    });
    return employees;
  } catch (error) {
    // console.log(error);
  }
}

export async function _getEmployee(id) {
  try {
    const docRef = doc(db, 'employees', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // console.log('No data available');
      return null;
    }
  } catch (error) {
    console.error(error);
    return;
  }
}

export async function _createEmployee(employee) {
  try {
    const response = await fetch('https://us-central1-time-snap.cloudfunctions.net/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(employee),
    });
    const data = await response.json();

    writeEmployeeData(employee, data.uid);
  } catch (error) {
    // console.log(error);
  }
}

export async function _updateEmployee(employee) {
  try {
    await updateDoc(doc(db, 'employees', employee.id), employee);
  } catch (error) {
    // console.log(error);
  }
}

export async function _writeEmployeeData(employee, uid) {
  setDoc(doc(db, 'employees', uid), { ...employee, id: uid });
}
