import { getClients } from '../api/clientCalls';
import { useEffect, useState } from 'react';
import LoadingBar from '../components/LoadingBar';
import ClientModal from '../modals/ClientModal';
import ClientsTable from '../modals/ClientsTable';

export default function Clients() {
  const [clients, setClients] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showClientModal, setShowClientModal] = useState(false);

  const editingClient = { ...selectedClient };

  function handleAddClick() {
    setShowClientModal(true);
  }
  function handleEditClick(client) {
    setSelectedClient(client);
    setShowClientModal(true);
  }
  function handleCancel() {
    setSelectedClient(null);
    setShowClientModal(false);
  }
  function handleSubmit() {
    getClients().then((clients) => {
      setClients(clients);
    });
    setSelectedClient(null);
    setShowClientModal(false);
  }

  useEffect(() => {
    getClients().then((clients) => {
      setClients(clients);
    });
  }, [setClients]);

  const activeClients = clients?.filter((client) => !client.archived);
  const archivedClients = clients?.filter((client) => client.archived);

  return (
    <div className="static 2xl:w-3/4 w-full max-h-full overflow-y-scroll">
      {showClientModal ? (
        <div className="absolute w-5/6 h-full top-0 z-50 bg-gray-500/50">
          <div className="h-full flex justify-center items-center ">
            <ClientModal client={editingClient} onCancel={handleCancel} onSubmit={handleSubmit} />
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="mx-12 mt-20 mb-16 px-4 sm:px-6 lg:px-8">
        {clients ? (
          <div>
            {activeClients.length > 0 ? (
              <ClientsTable onAdd={handleAddClick} onEdit={handleEditClick} clients={activeClients} />
            ) : (
              <div className="flex justify-between">
                <h1 className="lg:text-5xl sm:text-3xl font-extrabold">Aktuelle Kunden</h1>

                <button
                  type="button"
                  className="rounded-md bg-primary lg:px-20 sm:px-12 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight"
                  onClick={handleAddClick}
                >
                  Hinzufügen
                </button>
              </div>
            )}
            <br /> <br />
            {archivedClients.length > 0 ? (
              <ClientsTable onAdd={handleAddClick} onEdit={handleEditClick} clients={archivedClients} archived={true} />
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <LoadingBar small />
        )}
      </div>
    </div>
  );
}
