import { useState, useEffect } from 'react';
import { getProjects } from '../api/projectCalls';
import { getClients } from '../api/clientCalls';
import LoadingBar from '../components/LoadingBar';
import ProjectModal from '../modals/ProjectModal';
import ProjectsTable from '../modals/ProjectsTable';

export default function Projects() {
  const [projects, setProjects] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showProjectModal, setShowProjectModal] = useState(false);

  const editingProject = { ...selectedProject };

  function handleAddClick() {
    setShowProjectModal(true);
  }
  function handleEditClick(project) {
    setSelectedProject(project);
    setShowProjectModal(true);
  }
  function handleCancel() {
    setSelectedProject(null);
    setShowProjectModal(false);
  }
  function handleSubmit() {
    getProjects().then((projects) => {
      setProjects(projects);
    });
    setSelectedProject(null);
    setShowProjectModal(false);
  }

  useEffect(() => {
    getProjects().then((projects) => {
      setProjects(projects);
    });
    getClients().then((clients) => {
      setClients(clients);
    });
  }, [setProjects]);

  const activeProjects = projects?.filter((project) => !project.archived);
  const archivedProjects = projects?.filter((project) => project.archived);

  return (
    <div className="static 2xl:w-3/4 w-full h-full overflow-y-scroll">
      {showProjectModal && clients.length > 0 ? (
        <div className="absolute w-5/6 h-full top-0 z-50 bg-gray-500/50">
          <div className="h-full flex justify-center items-center">
            <ProjectModal project={editingProject} clients={clients} onCancel={handleCancel} onSubmit={handleSubmit} />
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="mx-12 mt-20 mb-16 px-4 sm:px-6 lg:px-8">
        {projects ? (
          <div>
            {activeProjects.length > 0 ? (
              <ProjectsTable onAdd={handleAddClick} onEdit={handleEditClick} projects={activeProjects} />
            ) : (
              <div className="flex justify-between">
                <h1 className="lg:text-5xl sm:text-3xl font-extrabold">Füge neues Projekt hinzu</h1>

                <button
                  type="button"
                  className="rounded-md bg-primary lg:px-20 sm:px-12 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight"
                  onClick={handleAddClick}
                >
                  Hinzufügen
                </button>
              </div>
            )}
            <br /> <br />
            {archivedProjects.length > 0 ? (
              <ProjectsTable onAdd={handleAddClick} onEdit={handleEditClick} projects={archivedProjects} archived={true} />
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <LoadingBar small />
        )}
      </div>
    </div>
  );
}
