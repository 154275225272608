import { useState, useEffect } from 'react';
import LoadingBar from '../components/LoadingBar';
import { getEmployees, deleteEmployee } from '../api/employeeCalls';
import EmployeeModal from '../modals/EmployeeModal';
import EmployeesTable from '../modals/EmployeesTable';
import SuccessModal from '../components/SuccessModal';

export default function Employees() {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const [show, setShow] = useState(false);

  const editingEmployee = { ...selectedEmployee };

  function handleAddClick() {
    setShowEmployeeModal(true);
  }
  function handleEditClick(employee) {
    setSelectedEmployee(employee);
    setShowEmployeeModal(true);
  }
  function handleCancel() {
    setSelectedEmployee(null);
    setShowEmployeeModal(false);
  }
  function handleSubmit() {
    setShow(true);
    getEmployees().then((employees) => {
      setEmployees(employees);
    });
    setSelectedEmployee(null);
    setShowEmployeeModal(false);
  }
  function handleClose() {
    setShow(false);
  }

  useEffect(() => {
    getEmployees().then((employees) => {
      // employees.forEach((element) => {
      //   deleteEmployee(element);
      // });
      setEmployees(employees);
    });
  }, [setEmployees]);

  const activeEmployees = employees?.filter((employee) => !employee.archived);
  const archivedEmployees = employees?.filter((employee) => employee.archived);

  return (
    <div className="static 2xl:w-3/4 w-full max-h-full overflow-y-scroll">
      {showEmployeeModal ? (
        <div className="absolute w-5/6 h-full top-0 z-50 bg-gray-500/50">
          <div className="h-full flex justify-center items-center">
            <EmployeeModal employee={editingEmployee} onCancel={handleCancel} onSubmit={handleSubmit} />
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="mx-12 mt-20 mb-16 px-4 sm:px-6 lg:px-8">
        {employees ? (
          <div>
            <EmployeesTable onAdd={handleAddClick} onEdit={handleEditClick} employees={activeEmployees} />
            <br /> <br />
            {archivedEmployees.length > 0 ? (
              <EmployeesTable onAdd={handleAddClick} onEdit={handleEditClick} employees={archivedEmployees} archived={true} />
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <LoadingBar small />
        )}
      </div>
      <SuccessModal title={'Mitarbeiter erfolgreich angelegt'} show={show} onHide={handleClose} />
    </div>
  );
}
