export default function DataProtection() {
  return (
    <div className="static 2xl:w-3/4 w-full max-h-full overflow-y-scroll">
      <div className="mx-12 mt-20 mb-16 px-4 sm:px-6 lg:px-8">
        <h1 className="lg:text-5xl sm:text-3xl font-extrabold">Datenschutzerklärung</h1>
        <div className="mt-14 flex flex-col gap-6">
          <div>
            <h1 className="font-bold">Kontakt Datenschutzbeauftragter</h1>
            <p>Vertreter in der Europäischen Union</p>
            <p>Elmana Vertrieb</p>
            <p>Übersicht der Verarbeitungen</p>
            <br />
            <p>
              Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die
              betroffenen Personen.
            </p>
          </div>
          <div>
            <h1 className="font-bold">Arten der verarbeiteten Daten</h1>
            <ul className="list-disc ml-6">
              <li>Bestandsdaten</li>
              <li>Zahlungsdaten</li>
              <li>Kontaktdaten</li>
              <li>Inhaltsdaten</li>
              <li>Vertragsdaten</li>
              <li>Nutzungsdaten</li>
              <li>Meta-/Kommunikationsdaten</li>
            </ul>
          </div>
          <div>
            <h1 className="font-bold">Kategorien betroffener Personen</h1>
            <ul className="list-disc ml-6">
              <li>Interessenten</li>
              <li>Kommunikationspartner</li>
              <li>Nutzer</li>
              <li>Geschäfts- und Vertragspartner</li>
            </ul>
          </div>
          <div>
            <h1 className="font-bold">Kategorien betroffener Personen</h1>
            <ul className="list-disc ml-6">
              <li>Erbringung vertraglicher Leistungen und Kundenservice</li>
              <li>Kontaktanfragen und Kommunikation</li>
              <li>Sicherheitsmaßnahmen</li>
              <li>Direktmarketing</li>
              <li>Reichweitenmessung</li>
              <li>Tracking</li>
              <li>Büro- und Organisationsverfahren</li>
              <li>Verwaltung und Beantwortung von Anfragen</li>
              <li>Feedback</li>
              <li>Marketing</li>
              <li>Profile mit nutzerbezogenen Informationen</li>
              <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit</li>
              <li>Informationstechnische Infrastruktur</li>
            </ul>
          </div>
          <div>
            <h1 className="font-bold">Maßgebliche Rechtsgrundlagen</h1>
            <p>
              Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte
              nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland
              gelten können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der
              Datenschutzerklärung mit.
            </p>
            <ul className="list-disc ml-6">
              <li>
                Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO) – Die betroffene Person hat ihre Einwilligung in die Verarbeitung der sie betreffenden
                personenbezogenen Daten für einen spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
              </li>
              <li>
                Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO) – Die Verarbeitung ist für die Erfüllung eines
                Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, die auf
                Anfrage der betroffenen Person erfolgen.
              </li>
              <li>
                Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO) – Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung
                erforderlich, der der Verantwortliche unterliegt.
              </li>
              <li>
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO) – Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
                Verantwortlichen oder eines Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen
                Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
              </li>
            </ul>
            <br />
            <p>
              Zusätzlich zu den Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale Regelungen zum Datenschutz in Deutschland.
              Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der Datenverarbeitung
              (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum
              Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur
              Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall einschließlich Profiling. Des Weiteren regelt es die
              Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick auf die Begründung, Durchführung oder
              Beendigung von Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner können Landesdatenschutzgesetze der
              einzelnen Bundesländer zur Anwendung gelangen.
            </p>
          </div>
          <div>
            <h1 className="font-bold">Sicherheitsmaßnahmen</h1>
            <p>
              Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der
              Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des
              Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische und organisatorische Maßnahmen, um ein dem
              Risiko angemessenes Schutzniveau zu gewährleisten. Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität
              und Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden
              Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren
              eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung der Daten
              gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von Hardware,
              Software sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche
              Voreinstellungen. SSL-Verschlüsselung (https): Um Ihre via unserem Online-Angebot übermittelten Daten zu schützen, nutzen wir eine
              SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der Adresszeile Ihres Browsers.
            </p>
          </div>
          <div>
            <h1 className="font-bold">Übermittlung von personenbezogenen Daten</h1>
            <p>
              Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten an andere Stellen, Unternehmen, rechtlich
              selbstständige Organisationseinheiten oder Personen übermittelt oder sie ihnen gegenüber offengelegt werden. Zu den Empfängern dieser
              Daten können z.B. mit IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und Inhalten, die in eine Webseite eingebunden
              werden, gehören. In solchen Fall beachten wir die gesetzlichen Vorgaben und schließen insbesondere entsprechende Verträge bzw.
              Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab. Datenverarbeitung in Drittländern Sofern wir Daten
              in einem Drittland (d.h., außerhalb der Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die
              Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw. Übermittlung von Daten an andere Personen,
              Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben. Vorbehaltlich ausdrücklicher
              Einwilligung oder vertraglich oder gesetzlich erforderlicher Übermittlung verarbeiten oder lassen wir die Daten nur in Drittländern mit
              einem anerkannten Datenschutzniveau, vertraglichen Verpflichtung durch sogenannte Standardschutzklauseln der EU-Kommission, beim
              Vorliegen von Zertifizierungen oder verbindlicher internen Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO, Informationsseite
              der EU-Kommission:{' '}
              <a
                className="underline"
                target="_blank"
                rel="noopener noreferrer"
                href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
              >
                https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
              </a>
              ).
            </p>
          </div>
          <div>
            <h1 className="font-bold">Löschung von Daten</h1>
            <p>
              Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten
              Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z.B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist
              oder sie für den Zweck nicht erforderlich sind). Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige
              Zwecke erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht für andere
              Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren
              Speicherung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
              oder juristischen Person erforderlich ist. Unsere Datenschutzhinweise können ferner weitere Angaben zu der Aufbewahrung und Löschung von
              Daten beinhalten, die für die jeweiligen Verarbeitungen vorrangig gelten.{' '}
            </p>
          </div>
          <div>
            <h1 className="font-bold">Einsatz von Cookies</h1>
            <p>
              Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die Informationen auf Endgeräten speichern und Informationen aus den
              Endgeräten auslesen. Z.B. um den Login-Status in einem Nutzerkonto, einen Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder
              verwendete Funktionen eines Onlineangebotes speichern. Cookies können ferner zu unterschiedlichen Zwecken eingesetzt werden, z.B. zu
              Zwecken der Funktionsfähigkeit, Sicherheit und Komfort von Onlineangeboten sowie der Erstellung von Analysen der Besucherströme.
              Hinweise zur Einwilligung: Wir setzen Cookies im Einklang mit den gesetzlichen Vorschriften ein. Daher holen wir von den Nutzern eine
              vorhergehende Einwilligung ein, außer wenn diese gesetzlich nicht gefordert ist. Eine Einwilligung ist insbesondere nicht notwendig,
              wenn das Speichern und das Auslesen der Informationen, also auch von Cookies, unbedingt erforderlich sind, um dem den Nutzern einen von
              ihnen ausdrücklich gewünschten Telemediendienst (also unser Onlineangebot) zur Verfügung zu stellen. Die widerrufliche Einwilligung wird
              gegenüber den Nutzern deutlich kommuniziert und enthält die Informationen zu der jeweiligen Cookie-Nutzung.
            </p>
            <p>
              Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: Auf welcher datenschutzrechtlichen Rechtsgrundlage wir die personenbezogenen Daten
              der Nutzer mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir Nutzer um eine Einwilligung bitten. Falls die Nutzer einwilligen,
              ist die Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung. Andernfalls werden die mithilfe von Cookies
              verarbeiteten Daten auf Grundlage unserer berechtigten Interessen (z.B. an einem betriebswirtschaftlichen Betrieb unseres
              Onlineangebotes und Verbesserung seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der Erfüllung unserer vertraglichen Pflichten
              erfolgt, wenn der Einsatz von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen. Zu welchen Zwecken die
              Cookies von uns verarbeitet werden, darüber klären wir im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren Einwilligungs-
              und Verarbeitungsprozessen auf.
            </p>
            <br />
            <p>Speicherdauer: Im Hinblick auf die Speicherdauer werden die folgenden Arten von Cookies unterschieden:</p>
            <ul className="list-disc ml-6">
              <li>
                Temporäre Cookies (auch: Session- oder Sitzungs-Cookies): Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein
                Online-Angebot verlassen und sein Endgerät (z.B. Browser oder mobile Applikation) geschlossen hat.
              </li>
              <li>
                Permanente Cookies: Permanente Cookies bleiben auch nach dem Schließen des Endgerätes gespeichert. So können beispielsweise der
                Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht. Ebenso können
                die mit Hilfe von Cookies erhobenen Daten der Nutzer zur Reichweitenmessung verwendet werden. Sofern wir Nutzern keine expliziten
                Angaben zur Art und Speicherdauer von Cookies mitteilen (z. B. im Rahmen der Einholung der Einwilligung), sollten Nutzer davon
                ausgehen, dass Cookies permanent sind und die Speicherdauer bis zu zwei Jahre betragen kann.
              </li>
            </ul>
            <br />
            <p>
              Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit widerrufen
              und zudem einen Widerspruch gegen die Verarbeitung entsprechend den gesetzlichen Vorgaben im Art. 21 DSGVO einlegen. Nutzer können ihren
              Widerspruch auch über die Einstellungen ihres Browsers erklären, z.B. durch Deaktivierung der Verwendung von Cookies (wobei dadurch auch
              die Funktionalität unserer Online-Dienste eingeschränkt sein kann). Ein Widerspruch gegen die Verwendung von Cookies zu
              Online-Marketing-Zwecken kann auch über die Websites{' '}
              <a className="underline" href="https://optout.aboutads.info" target="_blank" rel="noopener noreferrer">
                https://optout.aboutads.info
              </a>{' '}
              und{' '}
              <a className="underline" href="https://www.youronlinechoices.com/" target="_blank" rel="noopener noreferrer">
                https://www.youronlinechoices.com/
              </a>{' '}
              erklärt werden.
            </p>
            <br />
            <p>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</p>
            <ul className="list-disc ml-6">
              <li>
                Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management ein, in
                dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von Cookies, bzw. der im Rahmen des
                Cookie-Einwilligungs-Management-Verfahrens genannten Verarbeitungen und Anbieter eingeholt sowie von den Nutzern verwaltet und
                widerrufen werden können. Hierbei wird die Einwilligungserklärung gespeichert, um deren Abfrage nicht erneut wiederholen zu müssen und
                die Einwilligung entsprechend der gesetzlichen Verpflichtung nachweisen zu können. Die Speicherung kann serverseitig und/oder in einem
                Cookie (sogenanntes Opt-In-Cookie, bzw. mithilfe vergleichbarer Technologien) erfolgen, um die Einwilligung einem Nutzer, bzw. dessen
                Gerät zuordnen zu können. Vorbehaltlich individueller Angaben zu den Anbietern von Cookie-Management-Diensten, gelten die folgenden
                Hinweise: Die Dauer der Speicherung der Einwilligung kann bis zu zwei Jahren betragen. Hierbei wird ein pseudonymer
                Nutzer-Identifikator gebildet und mit dem Zeitpunkt der Einwilligung, Angaben zur Reichweite der Einwilligung (z. B. welche Kategorien
                von Cookies und/oder Diensteanbieter) sowie dem Browser, System und verwendeten Endgerät gespeichert.
              </li>
            </ul>
          </div>
          <div>
            <h1 className="font-bold">Geschäftliche Leistungen</h1>
            <p>
              Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B. Kunden und Interessenten (zusammenfassend bezeichnet als
              “Vertragspartner”) im Rahmen von vertraglichen und vergleichbaren Rechtsverhältnissen sowie damit verbundenen Maßnahmen und im Rahmen
              der Kommunikation mit den Vertragspartnern (oder vorvertraglich), z.B., um Anfragen zu beantworten.
            </p>
            <br />
            <p>
              Wir verarbeiten diese Daten, um unsere vertraglichen Verpflichtungen zu erfüllen. Dazu gehören insbesondere die Verpflichtungen zur
              Erbringung der vereinbarten Leistungen, etwaige Aktualisierungspflichten und Abhilfe bei Gewährleistungs- und sonstigen
              Leistungsstörungen. Darüber hinaus verarbeiten wir die Daten zur Wahrung unserer Rechte und zum Zwecke der mit diesen Pflichten
              verbundenen Verwaltungsaufgaben sowie der Unternehmensorganisation. Darüber hinaus verarbeiten wir die Daten auf Grundlage unserer
              berechtigten Interessen an einer ordnungsgemäßen und betriebswirtschaftlichen Geschäftsführung sowie an Sicherheitsmaßnahmen zum Schutz
              unserer Vertragspartner und unseres Geschäftsbetriebes vor Missbrauch, Gefährdung ihrer Daten, Geheimnisse, Informationen und Rechte
              (z.B. zur Beteiligung von Telekommunikations-, Transport- und sonstigen Hilfsdiensten sowie Subunternehmern, Banken, Steuer- und
              Rechtsberatern, Zahlungsdienstleistern oder Finanzbehörden). Im Rahmen des geltenden Rechts geben wir die Daten von Vertragspartnern nur
              insoweit an Dritte weiter, als dies für die vorgenannten Zwecke oder zur Erfüllung gesetzlicher Pflichten erforderlich ist. Über weitere
              Formen der Verarbeitung, z.B. zu Marketingzwecken, werden die Vertragspartner im Rahmen dieser Datenschutzerklärung informiert.
            </p>
            <br />
            <p>
              Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den Vertragspartnern vor oder im Rahmen der Datenerhebung, z.B.
              in Onlineformularen, durch besondere Kennzeichnung (z.B. Farben) bzw. Symbole (z.B. Sternchen o.ä.), oder persönlich mit.
            </p>
            <br />
            <p>
              Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten, d.h., grundsätzlich nach Ablauf von 4
              Jahren, es sei denn, dass die Daten in einem Kundenkonto gespeichert werden, z.B., solange sie aus gesetzlichen Gründen der Archivierung
              aufbewahrt werden müssen. Die gesetzliche Aufbewahrungsfrist beträgt bei steuerrechtlich relevanten Unterlagen sowie bei Handelsbüchern,
              Inventaren, Eröffnungsbilanzen, Jahresabschlüssen, die zum Verständnis dieser Unterlagen erforderlichen Arbeitsanweisungen und sonstigen
              Organisationsunterlagen und Buchungsbelegen zehn Jahre sowie bei empfangenen Handels- und Geschäftsbriefen und Wiedergaben der
              abgesandten Handels- und Geschäftsbriefe sechs Jahre. Die Frist beginnt mit Ablauf des Kalenderjahres, in dem die letzte Eintragung in
              das Buch gemacht, das Inventar, die Eröffnungsbilanz, der Jahresabschluss oder der Lagebericht aufgestellt, der Handels- oder
              Geschäftsbrief empfangen oder abgesandt worden oder der Buchungsbeleg entstanden ist, ferner die Aufzeichnung vorgenommen worden ist
              oder die sonstigen Unterlagen entstanden sind.
            </p>
            <p>
              Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder Plattformen einsetzen, gelten im Verhältnis zwischen den Nutzern und den
              Anbietern die Geschäftsbedingungen und Datenschutzhinweise der jeweiligen Drittanbieter oder Plattformen.
            </p>
            <br />
            <ul className="list-disc ml-6">
              <li>
                Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen); Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie);
                Kontaktdaten (z.B. E-Mail, Telefonnummern); Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).
              </li>
              <li>Betroffene Personen: Interessenten; Geschäfts- und Vertragspartner.</li>
              <li>
                Zwecke der Verarbeitung: Erbringung vertraglicher Leistungen und Kundenservice; Kontaktanfragen und Kommunikation; Büro- und
                Organisationsverfahren; Verwaltung und Beantwortung von Anfragen.
              </li>
              <li>
                Rechtsgrundlagen: Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO); Rechtliche Verpflichtung (Art. 6
                Abs. 1 S. 1 lit. c. DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>
            <br />
            <p>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</p>
            <ul className="list-disc ml-6">
              <li>
                Projekt- und Entwicklungsleistungen: Wir verarbeiten die Daten unserer Kunden sowie Auftraggeber (nachfolgend einheitlich als “Kunden”
                bezeichnet), um ihnen die Auswahl, den Erwerb bzw. die Beauftragung der gewählten Leistungen oder Werke sowie verbundener Tätigkeiten
                als auch deren Bezahlung und Zurverfügungstellung bzw. Ausführung oder Erbringung zu ermöglichen. Die erforderlichen Angaben sind als
                solche im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und umfassen die zur Leistungserbringung
                und Abrechnung benötigten Angaben sowie Kontaktinformationen, um etwaige Rücksprachen halten zu können. Soweit wir Zugang zu
                Informationen der Endkunden, Mitarbeitern oder anderer Personen erhalten, verarbeiten wir diese im Einklang mit den gesetzlichen und
                vertraglichen Vorgaben; Rechtsgrundlagen: Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO).
              </li>
              <li>
                Angebot von Software- und Plattformleistungen: Wir verarbeiten die Daten unserer Nutzer, angemeldeter und etwaiger Testnutzer
                (nachfolgend einheitlich als “Nutzer” bezeichnet), um ihnen gegenüber unsere vertraglichen Leistungen erbringen zu können sowie auf
                Grundlage berechtigter Interessen, um die Sicherheit unseres Angebotes gewährleisten und es weiterentwickeln zu können. Die
                erforderlichen Angaben sind als solche im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und
                umfassen die zur Leistungserbringung und Abrechnung benötigten Angaben sowie Kontaktinformationen, um etwaige Rücksprachen halten zu
                können; Rechtsgrundlagen: Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO).
              </li>
              <li>
                Technische Dienstleistungen: Wir verarbeiten die Daten unserer Kunden sowie Auftraggeber (nachfolgend einheitlich als “Kunden”
                bezeichnet), um ihnen die Auswahl, den Erwerb bzw. die Beauftragung der gewählten Leistungen oder Werke sowie verbundener Tätigkeiten
                als auch deren Bezahlung und Zurverfügungstellung bzw. Ausführung oder Erbringung zu ermöglichen. Die erforderlichen Angaben sind als
                solche im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und umfassen die zur Leistungserbringung
                und Abrechnung benötigten Angaben sowie Kontaktinformationen, um etwaige Rücksprachen halten zu können. Soweit wir Zugang zu
                Informationen der Endkunden, Mitarbeitern oder anderer Personen erhalten, verarbeiten wir diese im Einklang mit den gesetzlichen und
                vertraglichen Vorgaben; Rechtsgrundlagen: Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO).
              </li>
            </ul>
          </div>

          <div>
            <h1 className="font-bold">Bereitstellung des Onlineangebotes und Webhosting</h1>
            <p>
              Um unser Onlineangebot sicher und effizient bereitstellen zu können, nehmen wir die Leistungen von einem oder mehreren
              Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von ihnen verwalteten Servern) das Onlineangebot abgerufen werden kann. Zu
              diesen Zwecken können wir Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste sowie
              Sicherheitsleistungen und technische Wartungsleistungen in Anspruch nehmen.
            </p>
            <p>
              Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten Daten können alle die Nutzer unseres Onlineangebotes betreffenden
              Angaben gehören, die im Rahmen der Nutzung und der Kommunikation anfallen. Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist,
              um die Inhalte von Onlineangeboten an Browser ausliefern zu können, und alle innerhalb unseres Onlineangebotes oder von Webseiten
              getätigten Eingaben.
            </p>
            <ul className="list-disc ml-6">
              <li>
                Verarbeitete Datenarten: Inhaltsdaten (z.B. Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
              </li>
              <li>Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
              <li>
                Zwecke der Verarbeitung: Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische Infrastruktur
                (Betrieb und Bereitstellung von Informationssystemen und technischen Geräten (Computer, Server etc.)).
              </li>
              <li>Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
