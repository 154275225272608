import { db } from '../firebase';
import { collection, doc, getDocs, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';

export async function getTasks() {
  try {
    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/tasks/`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: '*/*',
      },
    });
    const data = await response.json();
    return data; // parses JSON response into native JavaScript objects
  } catch (error) {
    // console.log(error);
  }
}

export async function createTask(task) {
  try {
    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/tasks/`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(task), // body data type must match "Content-Type" header
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // console.log(error);
  }
}

export async function updateTask(task) {
  try {
    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/tasks/${task.id}/`, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(task),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // console.log(error);
  }
}

export async function deleteTask(taskId) {
  try {
    await fetch(`${process.env.REACT_APP_DATABASE_URL}/${taskId}/`, {
      method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: 'application/json',
      },
    });
    return;
  } catch (error) {
    // console.log(error);
  }
}

//////// LEGACY FIREBASE FUNCTIONS ///////////////

export async function _getTasks() {
  try {
    const querySnapshot = await getDocs(collection(db, 'tasks'));

    const tasks = [];
    querySnapshot.forEach(async (doc) => {
      // doc.data() is never undefined for query doc snapshots

      const task = {
        ...doc.data(),
        id: doc.id,
      };
      tasks.push(task);
    });
    return tasks;
  } catch (error) {
    // console.log(error);
  }
}

export async function _createTask(task) {
  try {
    await addDoc(collection(db, 'tasks'), task);
  } catch (error) {
    // console.log(error);
  }
}

export async function _updateTask(task) {
  try {
    await updateDoc(doc(db, 'tasks', task.id), task);
  } catch (error) {
    // console.log(error);
  }
}

export async function _deleteTask(taskId) {
  try {
    await deleteDoc(doc(db, 'tasks', taskId));
  } catch (error) {
    // console.log(error);
  }
}
