import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';

import { getEmployee } from '../api/employeeCalls';
import LoadingBar from './LoadingBar';
import {
  CalendarIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  ArrowDownIcon,
  ArrowRightIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline';

import { ReactComponent as WhiteLogo } from '../images/logo-white.svg';
import { classNames } from '../utils/classUtil';

const adminNavigation = [
  { name: 'Dashboard', href: '/home', icon: HomeIcon, current: true },
  { name: 'Kunden', href: '/clients', icon: UsersIcon, current: false },
  { name: 'Projekte', href: '/projects', icon: FolderIcon, current: false },
  { name: 'Tätigkeiten', href: '/tasks', icon: CalendarIcon, current: false },
  { name: 'Mitarbeiter', href: '/employees', icon: DocumentDuplicateIcon, current: false },
  { name: 'Exportieren', href: '/export', icon: ArrowDownIcon, current: false },
];
const userNavigation = [{ name: 'Dashboard', href: '/home', icon: HomeIcon, current: true }];
const miscellaneousNavigation = [
  {
    name: 'Impressum',
    href: '/imprint',
    icon: '',
    current: false,
  },
  {
    name: 'Datenschutzerklärung',
    href: '/data-protection',
    icon: '',
    current: false,
  },
  {
    name: 'Nutzungsbedingungen',
    //href: '/terms-of-use', // TODO: create Nutzungsbedingungen
    icon: '',
    current: false,
  },
];
//   const teams = [
//     { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
//     { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
//     { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
//   ]

export default function SidebarNavigation(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [navigation, navigationState] = useState(null);

  function handleSignOut() {
    signOut(auth)
      .then(() => {
        // console.log('Sign-out successful');
        navigate('/login');
        props.onSignOut();
      })
      .catch((err) => {
        console.error('An error occurred when signing out', err);
      });
  }

  useEffect(() => {
    const _user = auth.currentUser;
    if (_user) {
      getEmployee(_user.uid).then((data) => {
        if (data) {
          if (data.role === 'admin') {
            navigationState(adminNavigation);
          } else {
            navigationState(userNavigation);
          }
        }
      });
    } else {
      // user is not logged in
      navigate('/login');
    }
  }, [navigate]);

  return (
    <>
      <div className="flex grow flex-col bg-primary px-6 h-full">
        <nav className="flex flex-1 flex-col mt-20">
          {navigation ? (
            <ul className="flex flex-1 flex-col gap-y-9">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className={classNames(
                          location.pathname === item.href
                            ? 'bg-primaryDark text-white'
                            : 'text-primaryLight hover:text-white hover:bg-primaryLightDark',
                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            location.pathname === item.href ? 'text-white' : 'text-primaryLight group-hover:text-white',
                            'h-6 w-6 shrink-0'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                        {item.count ? (
                          <span
                            className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-primary px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-white ring-1 ring-inset ring-indigo-500"
                            aria-hidden="true"
                          >
                            {item.count}
                          </span>
                        ) : null}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>

              <li>
                <div className="flex flex-1 flex-col gap-y-2 space-y-1">
                  <div
                    onClick={handleSignOut}
                    className="text-primaryLight hover:text-white hover:bg-primaryLightDark cursor-pointer group flex gap-x-3 rounded-md text-sm leading-6 font-semibold"
                  >
                    <ArrowRightIcon className="text-primaryLight group-hover:text-white h-6 w-6 shrink-0" aria-hidden="true" />
                    Ausloggen
                  </div>
                  <Link
                    to={'/settings'}
                    className={classNames(
                      location.pathname === '/settings'
                        ? 'bg-primaryDark text-white'
                        : 'text-primaryLight hover:text-white hover:bg-primaryLightDark',
                      'group flex gap-x-3 rounded-md  text-sm leading-6 font-semibold'
                    )}
                  >
                    <Cog6ToothIcon className="text-primaryLight group-hover:text-white h-6 w-6 shrink-0" aria-hidden="true" />
                    Einstellungen
                  </Link>
                </div>
              </li>

              <li className="mb-10 mt-auto">
                <WhiteLogo className="w-12" />
                <div className="mt-4 flex flex-wrap">
                  {miscellaneousNavigation.map((item, index) => {
                    return (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          location.pathname === item.href
                            ? 'bg-primaryDark text-white'
                            : 'text-primaryLight hover:text-white hover:bg-primaryLightDark',
                          'rounded-md text-xs font-semibold'
                        )}
                      >
                        {item.name}
                        {index < miscellaneousNavigation.length - 1 ? <span>&nbsp;&middot;&nbsp;</span> : <span></span>}
                      </Link>
                    );
                  })}
                </div>
              </li>
            </ul>
          ) : (
            <div>
              <LoadingBar tiny />
            </div>
          )}
        </nav>
      </div>
    </>
  );
}
