export function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

export function formatDateBackend(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export function displayToday() {
  const date = new Date();
  const getWeekday = (date) => ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'][date.getDay()];
  const weekday = getWeekday(date);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${weekday}, ${day}.${month}.${year}`;
}

export function convertDate(date, delimiter = '-') {
  const [day, month, year] = date.split(delimiter);
  let initialJSDate = new Date(year, Number(month) - 1, Number(day));
  
  // Format the date using toLocaleDateString to account for timezone
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const formattedInitialDate = initialJSDate.toLocaleDateString(undefined, options);

  return formattedInitialDate;
}

