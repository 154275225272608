import { useState, useEffect } from 'react';
import { createProject, updateProject } from '../api/projectCalls';
import { classNames } from '../utils/classUtil';
import ComboField from '../components/ComboField';

export default function ProjectModal(props) {
  const [project, setProject] = useState({ ...props.project });
  const [dataComplete, setDataComplete] = useState(false);

  const editMode = Object.keys(props.project).length > 0 ? true : false;

  const handleSubmit = async () => {
    setDataComplete(false);
    if (editMode) {
      // we are in edit mode
      await updateProject(project);
    } else {
      // we are in create mode
      await createProject(project);
    }
    props.onSubmit();
  };
  const handleCancel = () => props.onCancel();

  useEffect(() => {
    if (project.name && project.client) {
      setDataComplete(true);
    } else {
      setDataComplete(false);
    }
  }, [project]);

  return (
    <div className="bg-white shadow rounded-lg w-1/2">
      <div className="my-8 mx-8 sm:p-6">
        <h3 className="text-3xl font-bold leading-6 text-primary">Projekt {editMode ? 'bearbeiten' : 'anlegen'}</h3>
        <div className="mt-5 relative">
          <form>
            <div className="grid gap-6">
              <div className="w-full">
                <label htmlFor="project-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Projektname
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="project-name"
                    id="project-name"
                    className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                    value={project.name ?? ''}
                    onChange={(e) => setProject({ ...project, name: e.target.value })}
                  />
                </div>
              </div>
              <div className="w-full">
                <label className="block text-sm font-medium leading-6 text-gray-900">Kunde</label>
                <ComboField entrys={props.clients} onChange={(val) => setProject({ ...project, client: val })} />
                {/* <label htmlFor="project-client" className="block text-sm font-medium leading-6 text-gray-900">
                  Kunde
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="project-client"
                    id="project-client"
                    className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                    value={project.client ?? ''}
                    onChange={(e) => setProject({ ...project, client: e.target.value })}
                  />
                </div> */}
              </div>
              <div className="w-full">
                <label htmlFor="project-budget" className="block text-sm font-medium leading-6 text-gray-900">
                  Budget
                </label>
                <div className="mt-2">
                  <input
                    type="number"
                    name="project-budget"
                    id="project-budget"
                    className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                    value={project.budget ?? ''}
                    onChange={(e) => setProject({ ...project, budget: e.target.value })}
                  />
                </div>
              </div>
              <div className="w-full">
                <label htmlFor="project-rate" className="block text-sm font-medium leading-6 text-gray-900">
                  Tagessatz
                </label>
                <div className="mt-2">
                  <input
                    type="number"
                    name="project-rate"
                    id="project-rate"
                    className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                    value={project.daily_rate ?? ''}
                    onChange={(e) => setProject({ ...project, daily_rate: e.target.value })}
                  />
                </div>
              </div>
              {editMode ? (
                <div className="flex gap-2 items-center">
                  <input
                    className="rounded border-1 border-gray-300 shadow-sm focus:ring-0"
                    type="checkbox"
                    id="archive"
                    name="archive"
                    checked={project.archived ?? false}
                    onChange={(e) => setProject({ ...project, archived: e.target.checked })}
                  />
                  <label htmlFor="archive">Projekt ins Archiv verschieben</label>
                </div>
              ) : (
                <span></span>
              )}
            </div>

            <div className="mt-12 flex items-center gap-x-6">
              <button
                type="button"
                className={classNames(
                  'rounded-md bg-primary px-12 py-3 text-sm font-semibold text-white shadow-sm',
                  dataComplete
                    ? 'bg-primary hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight'
                    : 'bg-primaryLight'
                )}
                onClick={handleSubmit}
              >
                Speichern
              </button>
              <button
                type="button"
                className="rounded-md bg-white border-2 px-12 py-3 text-sm font-semibold shadow-sm hover:bg-gray-200"
                onClick={handleCancel}
              >
                Abbrechen
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
