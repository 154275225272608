import { classNames } from '../utils/classUtil';

export default function ProjectsTable(props) {
  return (
    <>
      <div className="flex align-items-end justify-between">
        <h1 className={classNames('lg:text-5xl sm:text-3xl font-extrabold', props.archived ? 'text-gray-300' : '')}>
          {props.archived ? 'Archivierte' : 'Aktuelle'} Projekte
        </h1>

        {!props.archived ? (
          <button
            type="button"
            className="rounded-md bg-primary lg:px-20 sm:px-12 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight"
            onClick={props.onAdd}
          >
            Hinzufügen
          </button>
        ) : (
          <div></div>
        )}
      </div>
      <div className="mt-8 flow-root bg-white sm:px-6 lg:px-8 lg:py-6 sm:py-4 shadow rounded-3xl max-h-96 overflow-y-scroll">
        <table className="w-full inline-block align-middle">
          <thead>
            <tr>
              <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 sm:pl-0">
                <span className="sr-only">Name</span>
              </th>
              <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 sm:pl-0">
                Kunde
              </th>
              <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                Budget
              </th>
              <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                Tagessatz
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">Bearbeiten</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {props.projects.map((project) => (
              <tr key={project.id}>
                <td className="whitespace-nowrap  px-3 py-4 text-sm font-bold">{project.name}</td>
                <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">{project.client}</td>
                <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">{project.budget}</td>
                <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">{project.daily_rate}</td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                  <button className="text-primary hover:text-indigo-900" onClick={() => props.onEdit(project)}>
                    Bearbeiten
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
