import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { updateTimeData } from '../api/workingTimesCalls';

import DateField from '../components/DateField';
import TimeField from '../components/TimeField';
import ComboField from '../components/ComboField';
import { getTimeDifference } from '../utils/timeUtils';
import { classNames } from '../utils/classUtil';
import { convertDate } from '../utils/dateUtils';

const EditTime = (props) => {
  const [worktime, setWorktime] = useState({ ...props.worktime });
  const [timesUnfit, setTimesUnfit] = useState(false);
  const [dataComplete, setDataComplete] = useState(false);

  const handleSubmit = async function () {
    setDataComplete(false);

    await updateTimeData(worktime);
    // // console.log('written data!');

    props.onAdded(worktime.start, worktime.end);
    setDataComplete(true);
  };

  const handleCancel = async function () {
    props.onCancel();
  };

  useEffect(() => {
    const _user = auth.currentUser;
    if (_user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      worktime.user = _user;

      //const durationField = document.getElementById('durationInput'); // Change to the actual ID of your duration input field
      if (worktime.end !== undefined && worktime.start !== undefined) {
        if (worktime.end <= worktime.start) {
          // we throw an error that lets the user know the times are not acceptable
          setTimesUnfit(true);
        } else {
          setTimesUnfit(false);
          const duration = getTimeDifference(worktime.start, worktime.end);
          if (duration !== worktime.duration) {
            setWorktime({ ...worktime, duration: duration });
          }
        }
      }

      if (worktime.start && worktime.end && worktime.client && worktime.project && worktime.task) {
        setDataComplete(true);
      } else {
        setDataComplete(false);
      }
    }
  }, [worktime]);

  if (Object.keys(worktime).length > 0) {
    return (
      <div className="bg-white shadow rounded-lg w-1/2">
        <div className="my-8 mx-8 sm:p-6">
          <h3 className="text-3xl font-bold leading-6 text-primary">Zeit bearbeiten</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500"></div>
          <div className="grid gap-6 mt-5 relative">
            <DateField initialDate={worktime.date} onChange={(val) => (worktime.date = val)} />
            <div className="flex flex-col">
              <div className="flex w-full justify-between items-center">
                <TimeField
                  id="startTimeInput"
                  placeholder="Start"
                  value={worktime.start || ''}
                  onChange={(val) => {
                    setWorktime({ ...worktime, start: val });
                  }}
                  error={timesUnfit}
                />
                <span className="text-2xl">-</span>
                <TimeField
                  id="endTimeInput"
                  placeholder="Ende"
                  value={worktime.end || ''}
                  onChange={(val) => {
                    setWorktime({ ...worktime, end: val });
                  }}
                  error={timesUnfit}
                />
              </div>
              {timesUnfit ? <span className="absolute text-sm mt-16 text-rose-500">Die Endzeit kann nicht vor der Startzeit liegen</span> : <></>}
            </div>
            <ComboField
              placeholder={'Kunde'}
              entrys={props.clients}
              initialValue={worktime.client}
              onChange={(val) => setWorktime({ ...worktime, client: val })}
            />
            <ComboField
              placeholder={'Projekt'}
              entrys={props.projects}
              initialValue={worktime.project}
              onChange={(val) => setWorktime({ ...worktime, project: val })}
            />
            <ComboField
              placeholder={'Tätigkeit'}
              entrys={props.tasks}
              initialValue={worktime.task}
              onChange={(val) => setWorktime({ ...worktime, task: val })}
            />
          </div>
          <div className="mt-12 flex items-center gap-x-6">
            <button
              id="time-submit"
              disabled={timesUnfit || !dataComplete}
              type="button"
              onClick={handleSubmit}
              className={classNames(
                'rounded-md bg-primary px-14 py-3 text-sm font-semibold text-white shadow-sm',
                !timesUnfit && dataComplete
                  ? 'bg-primary hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight'
                  : 'bg-primaryLight'
              )}
            >
              Bestätigen
            </button>
            <button className="rounded-md bg-white border-2 px-14 py-3 text-sm font-semibold shadow-sm hover:bg-gray-200" onClick={handleCancel}>
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    <></>;
  }
};

export default EditTime;
