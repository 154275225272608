import React from 'react';

export default function LoadingBar(props) {
  let sizeClass = 'large';
  let colorClass = 'blue';
  if (props.small) {
    sizeClass = 'small';
  }
  if (props.tiny) {
    sizeClass = 'tiny';
    colorClass = 'white';
  }
  return (
    <div className="grid w-full items-center justify-items-center">
      <div className={`lds-dual-ring ${sizeClass} ${colorClass}`}></div>
    </div>
  );
}
