import { useState, useEffect } from 'react';
import { createEmployee, updateEmployee } from '../api/employeeCalls';
import { classNames } from '../utils/classUtil';
import { ReactComponent as HidePassword } from '../images/hide-password-icon.svg';
import { ReactComponent as ShowPassword } from '../images/show-password-icon.svg';

export default function EmployeeModal(props) {
  const [employee, setEmployee] = useState({ ...props.employee });
  const [dataComplete, setDataComplete] = useState(false);
  const [debouncedInternalHourly, setDebouncedInternalHourly] = useState(null);
  const [debouncedExternalHourly, setDebouncedExternalHourly] = useState(null);
  const [debouncedInternalDaily, setDebouncedInternalDaily] = useState(null);
  const [debouncedExternalDaily, setDebouncedExternalDaily] = useState(null);
  const [debouncedweekly_hours, setDebouncedweekly_hours] = useState(null);
  const [debouncedmonthly_hours, setDebouncedmonthly_hours] = useState(null);
  const [passwordVisible, togglePasswordVisible] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const editMode = Object.keys(props.employee).length > 0 ? true : false;

  const handleSubmit = async () => {
    setDataComplete(false);
    if (editMode) {
      // we are in edit mode
      await updateEmployee(employee, passwordChanged);
    } else {
      // we are in create mode
      await createEmployee(employee);
    }
    props.onSubmit();
  };
  const handleCancel = () => props.onCancel();

  const updateRatesInternal = (rate, isHourly) => {
    rate = Number(rate);
    if (isHourly) {
      setEmployee({ ...employee, internal_hourly_rate: rate });
      if (!employee.internal_daily_rate) {
        const daily_rate = rate * 8;
        setDebouncedInternalDaily(daily_rate);
      }
    } else {
      setEmployee({ ...employee, internal_daily_rate: rate });
      if (!employee.internal_hourly_rate) {
        const hourly_rate = Math.round(rate / 8);
        setDebouncedInternalHourly(hourly_rate);
      }
    }
  };

  const updateRatesExternal = (rate, isHourly) => {
    rate = Number(rate);
    if (isHourly) {
      setEmployee({ ...employee, external_hourly_rate: rate });
      if (!employee.external_daily_rate) {
        const daily_rate = rate * 8;
        setDebouncedExternalDaily(daily_rate);
      }
    } else {
      setEmployee({ ...employee, external_daily_rate: rate });
      if (!employee.external_hourly_rate) {
        const hourly_rate = Math.round(rate / 8);
        setDebouncedExternalHourly(hourly_rate);
      }
    }
  };

  const updateWorkingTimes = (hours, isWeekly) => {
    if (isWeekly) {
      setEmployee({ ...employee, weekly_hours: hours });
      if (!employee.monthly_hours) {
        const montly_hours = hours * 4;
        setDebouncedmonthly_hours(montly_hours);
      }
    } else {
      setEmployee({ ...employee, monthly_hours: hours });
      if (!employee.weekly_hours) {
        const weekly_hours = Math.round(hours / 4);
        setDebouncedweekly_hours(weekly_hours);
      }
    }
  };

  useEffect(() => {
    // This is a custom debouncer to set the working hours concurrently
    const timeoutId = setTimeout(() => {
      const newEmployee = { ...employee };
      newEmployee.weekly_hours = newEmployee.weekly_hours || debouncedweekly_hours;
      newEmployee.monthly_hours = newEmployee.monthly_hours || debouncedmonthly_hours;
      setEmployee({ ...newEmployee });
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [debouncedweekly_hours, debouncedmonthly_hours]);

  useEffect(() => {
    // This is a custom debouncer to set the rates concurrently
    const timeoutId = setTimeout(() => {
      const newEmployee = { ...employee };
      newEmployee.internal_hourly_rate = newEmployee.internal_hourly_rate || debouncedInternalHourly;
      newEmployee.internal_daily_rate = newEmployee.internal_daily_rate || debouncedInternalDaily;

      setEmployee({ ...newEmployee });
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [debouncedInternalHourly, debouncedInternalDaily]);

  useEffect(() => {
    // This is a custom debouncer to set the rates concurrently
    const timeoutId = setTimeout(() => {
      const newEmployee = { ...employee };
      newEmployee.external_hourly_rate = newEmployee.external_hourly_rate || debouncedExternalHourly;
      newEmployee.external_daily_rate = newEmployee.external_daily_rate || debouncedExternalDaily;

      setEmployee({ ...newEmployee });
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [debouncedExternalHourly, debouncedExternalDaily]);

  useEffect(() => {
    if (employee.firstname && employee.lastname && employee.email) {
      if (!editMode && employee.password) {
        setDataComplete(true);
      } else if (editMode) {
        setDataComplete(true);
      }
    } else {
      setDataComplete(false);
    }
  }, [employee, editMode]);

  return (
    <div className="bg-white shadow rounded-lg w-5/6 max-h-full overflow-y-scroll">
      <div className="my-8 mx-8 sm:p-6">
        <h3 className="text-3xl font-bold leading-6 text-primary">{editMode ? 'Mitarbeiter bearbeiten' : 'Neuen Mitarbeiter anlegen'}</h3>
        <div className="mt-5 relative">
          <form>
            <div className="grid gap-3">
              <span className="font-bold mt-2 text-gray-300 text-xl">Personalinfos</span>
              <div className="w-full flex gap-6">
                <div className="w-full">
                  <label htmlFor="employee-firstname" className="block text-sm font-medium leading-6 text-gray-900">
                    Vorname
                  </label>
                  <input
                    type="text"
                    name="employee-firstname"
                    id="employee-firstname"
                    className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                    value={employee.firstname ?? ''}
                    onChange={(e) => setEmployee({ ...employee, firstname: e.target.value })}
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="employee-lastname" className="block text-sm font-medium leading-6 text-gray-900">
                    Nachname
                  </label>
                  <input
                    type="text"
                    name="employee-lastname"
                    id="employee-lastname"
                    className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                    value={employee.lastname ?? ''}
                    onChange={(e) => setEmployee({ ...employee, lastname: e.target.value })}
                  />
                </div>
              </div>

              <div className="w-full flex gap-6 mt-2">
                <div className="w-full">
                  <label htmlFor="employee-email" className="block text-sm font-medium leading-6 text-gray-900">
                    E-mail
                  </label>
                  <input
                    type="text"
                    name="employee-email"
                    id="employee-email"
                    className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                    value={employee.email ?? ''}
                    onChange={(e) => setEmployee({ ...employee, email: e.target.value })}
                  />
                </div>
                {!editMode ? (
                  <div className="w-full ">
                    <label htmlFor="employee-password" className="block text-sm font-medium leading-6 text-gray-900">
                      Passwort
                    </label>

                    <div className="relative flex items-center">
                      <input
                        type={passwordVisible ? 'text' : 'password'}
                        name="employee-password"
                        id="employee-password"
                        className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                        value={employee.password ?? ''}
                        onChange={(e) => {
                          setPasswordChanged(true);
                          setEmployee({ ...employee, password: e.target.value });
                        }}
                      />
                      <ShowPassword
                        onClick={() => togglePasswordVisible(false)}
                        className={classNames('absolute cursor-pointer right-2 z-10', passwordVisible ? '' : 'hidden')}
                      />
                      <HidePassword
                        onClick={() => togglePasswordVisible(true)}
                        className={classNames('absolute cursor-pointer right-2 z-10', passwordVisible ? 'hidden' : '')}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <label htmlFor="employee-position" className="block text-sm font-medium leading-6 text-gray-900">
                  Position
                </label>
                <input
                  type="text"
                  name="employee-position"
                  id="employee-position"
                  className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                  value={employee.position ?? ''}
                  onChange={(e) => setEmployee({ ...employee, position: e.target.value })}
                />
              </div>

              <div className="grid gap-3">
                <span className="font-bold mt-2 text-gray-300 text-xl">Infos zu Arbeitszeiten</span>

                <div className="w-full flex gap-6">
                  <div className="w-full">
                    <label htmlFor="employee-weekly_hours" className="block text-sm font-medium leading-6 text-gray-900">
                      Stunden pro Woche
                    </label>
                    <input
                      type="number"
                      name="employee-weekly_hours"
                      id="employee-weekly_hours"
                      className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                      value={employee.weekly_hours ?? ''}
                      onChange={(e) => updateWorkingTimes(e.target.value, true)}
                    />
                  </div>
                  <div className="w-full">
                    <label htmlFor="employee-montly_hours" className="block text-sm font-medium leading-6 text-gray-900">
                      Stunden pro Monat
                    </label>
                    <input
                      type="number"
                      name="employee-montly_hours"
                      id="employee-montly_hours"
                      className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                      value={employee.monthly_hours ?? ''}
                      onChange={(e) => updateWorkingTimes(e.target.value, false)}
                    />
                  </div>
                </div>

                <div className="w-full flex gap-6">
                  <div className="w-full">
                    <label htmlFor="employee-hourly_rate" className="block text-sm font-medium leading-6 text-gray-900">
                      Stundensatz (intern)
                    </label>
                    <input
                      type="number"
                      name="employee-hourly_rate"
                      id="employee-hourly_rate"
                      className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                      value={employee.internal_hourly_rate ?? ''}
                      onChange={(e) => updateRatesInternal(e.target.value, true)}
                    />
                  </div>
                  <div className="w-full">
                    <label htmlFor="employee-daily_rate" className="block text-sm font-medium leading-6 text-gray-900">
                      Tagessatz (intern)
                    </label>
                    <input
                      type="number"
                      name="employee-daily_rate"
                      id="employee-daily_rate"
                      className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                      value={employee.internal_daily_rate ?? ''}
                      onChange={(e) => updateRatesInternal(e.target.value, false)}
                    />
                  </div>
                </div>

                <div className="w-full flex gap-6 mt-2">
                  <div className="w-full">
                    <label htmlFor="employee-hourly_rate-external" className="block text-sm font-medium leading-6 text-gray-900">
                      Stundensatz (extern)
                    </label>
                    <input
                      type="number"
                      name="employee-hourly_rate-external"
                      id="employee-hourly_rate-external"
                      className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                      value={employee.external_hourly_rate ?? ''}
                      onChange={(e) => updateRatesExternal(e.target.value, true)}
                    />
                  </div>
                  <div className="w-full">
                    <label htmlFor="employee-daily_rate-external" className="block text-sm font-medium leading-6 text-gray-900">
                      Tagessatz (extern)
                    </label>
                    <input
                      type="number"
                      name="employee-daily_rate-external"
                      id="employee-daily_rate-external"
                      className="w-full rounded-md border-0 py-2.5 px-4 pl-3 pr-12 shadow-sm text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 bg-gray-200"
                      value={employee.external_daily_rate ?? ''}
                      onChange={(e) => updateRatesExternal(e.target.value, false)}
                    />
                  </div>
                </div>
              </div>
              {editMode ? (
                <div className="flex gap-2 items-center">
                  <input
                    className="rounded border-1 border-gray-300 shadow-sm focus:ring-0"
                    type="checkbox"
                    id="archive"
                    name="archive"
                    checked={employee.archived ?? false}
                    onChange={(e) => setEmployee({ ...employee, archived: e.target.checked })}
                  />
                  <label htmlFor="archive">Mitarbeiter ins Archiv verschieben</label>
                </div>
              ) : (
                <span></span>
              )}
            </div>

            <div className="mt-12 flex items-center gap-x-6">
              <button
                type="button"
                className={classNames(
                  'rounded-md bg-primary px-12 py-3 text-sm font-semibold text-white shadow-sm',
                  dataComplete
                    ? 'bg-primary hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryLight'
                    : 'bg-primaryLight'
                )}
                onClick={handleSubmit}
              >
                Speichern
              </button>
              <button
                type="button"
                className="rounded-md bg-white border-2 px-12 py-3 text-sm font-semibold shadow-sm hover:bg-gray-200"
                onClick={handleCancel}
              >
                Abbrechen
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
