import { db } from '../firebase';
import { collection, getDocs, where, query, limit, updateDoc, doc, Timestamp, addDoc } from 'firebase/firestore';
import { formatDate, convertDate, formatDateBackend } from '../utils/dateUtils';
import { extractFormattedTime } from '../utils/timeUtils';

//////// FUNCTIONS FOR FIREBASE DB //////////
export async function getuserWorkingTimes(currentUser, startDate, endDate) {
  const docRef = query(
    collection(db, 'times'),
    limit(200),
    where('user', '==', currentUser),
    where('date', '>=', startDate),
    where('date', '<=', endDate)
  );

  const querySnapshot = await getDocs(docRef);

  let workingTimes = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    const workingTime = {
      ...doc.data(),
      id: doc.id,
    };
    if (workingTime.date) {
      workingTime.date = convertDate(formatDate(workingTime.date.toDate()));
    }
    if (workingTime.client) {
      workingTime.client = workingTime.client.split('/').pop();
    }
    if (workingTime.project) {
      workingTime.project = workingTime.project.split('/').pop();
    }
    if (workingTime.task) {
      workingTime.task = workingTime.task.split('/').pop();
    }
    workingTimes.push(workingTime);
  });

  workingTimes.sort(function (a, b) {
    if (a.date < b.date) {
      return -1;
    } else if (a.date > b.date) {
      return 1;
    }
    if (a.start < b.start) {
      return -1;
    } else if (a.start > b.start) {
      return 1;
    }
    if (a.end < b.end) {
      return -1;
    } else if (a.end > b.end) {
      return 1;
    }
    return 0;
  });

  return workingTimes;
}

export async function writeTimeData(workTime) {
  // // console.log("workTime", workTime)
  // const formattedDate = format(new Date(workTime.date), 'yyyy-MM-dd HH:mm');

  await addDoc(collection(db, `times`), {
    billed: false,
    client: `clients/${workTime.client}`,
    date: Timestamp.fromDate(new Date(workTime.date)),
    duration: workTime.duration,
    start: workTime.start,
    end: workTime.end,
    project: `projects/${workTime.project}`,
    task: `tasks/${workTime.task}`,
    user: workTime.user.uid,
  });
}

export async function getWorkingTimes(startDate, endDate, client, project, billed, task) {
  // const queryAll = client === 'Alle Kunden' && project === 'Alle Projekte' && task === 'Alle Aufgaben';
  // Gemeinsame Abfrage-Teile
  let docRef = collection(db, 'times');
  if (client !== 'Alle Kunden') {
    docRef = query(docRef, where('client', '==', `clients/${client}`));
  }
  if (project !== 'Alle Projekte') {
    docRef = query(docRef, where('project', '==', `projects/${project}`));
  }
  if (task !== 'Alle Aufgaben') {
    docRef = query(docRef, where('task', '==', `tasks/${task}`));
  }
  if (billed !== 'Alle Status') {
    docRef = query(docRef, where('billed', '==', billed === 'Abgerechnet'));
  }
  
  // Füge allgemeine Bedingungen wie 'date' hinzu
  docRef = query(docRef, where('date', '>=', startDate), where('date', '<=', endDate));
  // // console.log(client, project, billed, task);

  const querySnapshot = await getDocs(docRef);
  let workingTimes = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    const workingTime = {
      id: doc.id,
      ...doc.data(),
    };
    if (workingTime.date) {
      workingTime.date = convertDate(formatDate(workingTime.date.toDate()));
    }
    if (workingTime.client) {
      workingTime.client = workingTime.client.split('/').pop();
    }
    if (workingTime.project) {
      workingTime.project = workingTime.project.split('/').pop();
    }
    if (workingTime.task) {
      workingTime.task = workingTime.task.split('/').pop();
    }
    workingTimes.push(workingTime);
    // // console.log(doc.id, " => ", doc.data());
  });
  // // console.log(workingTimes)
  return workingTimes;
}

export async function updateTimeData(workTime) {
  let workDate = workTime.date

  if (workDate.includes("/")) {
    const [day, month, year] = workDate.split("/");
    
    workDate = `${year}-${month}-${day}`
  }

  const workTimeDoc = {
    billed: workTime.billed || false,
    client: `clients/${workTime.client}`,
    date: Timestamp.fromDate(new Date(workDate)),
    duration: workTime.duration,
    start: workTime.start,
    end: workTime.end,
    project: `projects/${workTime.project}`,
    task: `tasks/${workTime.task}`,
    user: workTime.user.uid,
  };

  // const formattedDate = format(new Date(workTime.date), 'yyyy-MM-dd HH:mm');
  await updateDoc(doc(db, `times`, workTime.id), workTimeDoc);
  // console.log('updated time data');
}

export async function updateWorkingTimesBilling(workTime, resolve) {
  // // console.log("workTime", workTime)
  // const formattedDate = format(new Date(workTime.date), 'yyyy-MM-dd HH:mm');
  await updateDoc(doc(db, `times`, workTime.id), {
    billed: workTime.billed || false,
  });
  resolve();
}

//////// FUNCTIONS FOR Backend BACKEND //////////

export async function _getWorkingTimes(start, end, client, project, billed, task) {
  const startDate = extractFormattedTime(start);
  const endDate = extractFormattedTime(end); // format will be "YYYY-MM-DD"
  let docQuery = '';
  if (billed === 'Alle Status') {
    docQuery = `?billed=&startDate=${startDate}&endDate=${endDate}&project=${project}&task=${task}/`;
  } else {
    docQuery = `?billed=${billed === 'Abgerechnet' ? true : false}&startDate=${startDate}&endDate=${endDate}&project=${project}&task=${task}/`;
  }

  const url = `${process.env.REACT_APP_DATABASE_URL}/times` + docQuery;
  const response = await fetch(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    headers: {
      Accept: '*/*',
    },
  });

  const times = await response.json();

  let workingTimes = [];
  times.forEach((time) => {
    const workingTime = {
      ...time,
    };
    if (workingTime.date) {
      workingTime.date = convertDate(formatDate(workingTime.date.toDate()));
    }
    if (workingTime.client) {
      workingTime.client = workingTime.client.split('/').pop();
    }
    if (workingTime.project) {
      workingTime.project = workingTime.project.split('/').pop();
    }
    if (workingTime.task) {
      workingTime.task = workingTime.task.split('/').pop();
    }
    workingTimes.push(workingTime);
  });
  return workingTimes;
}

export async function _getuserWorkingTimes(userId, start, end) {
  try {
    const startDate = extractFormattedTime(start);
    const endDate = extractFormattedTime(end); // format will be "YYYY-MM-DD"

    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/times/?startDate=${startDate}&endDate=${endDate}&employee=${userId}/`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: '*/*',
      },
    });
    const times = await response.json();
    return times;
  } catch (error) {
    // console.log(error);
  }
}

export async function _getTime(timeId) {
  try {
    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/times/${timeId}/`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: '*/*',
      },
    });
    const workingTime = await response.json();

    if (workingTime.date) {
      workingTime.date = convertDate(formatDate(workingTime.date.toDate()));
    }
    if (workingTime.client) {
      workingTime.client = workingTime.client.split('/').pop();
    }
    if (workingTime.project) {
      workingTime.project = workingTime.project.split('/').pop();
    }
    if (workingTime.task) {
      workingTime.task = workingTime.task.split('/').pop();
    }
    return workingTime;
  } catch (error) {
    // console.log(error);
  }
}

export async function _writeTimeData(workTime) {
  try {
    // console.log('workTime', workTime);
    const workTimeDoc = {
      billed: workTime.billed || false,
      client: `clients/${workTime.client}`,
      date: Timestamp.fromDate(new Date(workTime.date)),
      duration: workTime.duration,
      start: workTime.start,
      end: workTime.end,
      project: `projects/${workTime.project}`,
      task: `tasks/${workTime.task}`,
      user: workTime.user.uid,
    };

    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/times/`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(workTimeDoc), // body data type must match "Content-Type" header
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // console.log(error);
  }
}

export async function _updateTimeData(workTime) {
  try {
    // console.log('workTime', workTime);
    const workTimeDoc = {
      billed: workTime.billed || false,
      client: `clients/${workTime.client}`,
      date: Timestamp.fromDate(new Date(workTime.date)),
      duration: workTime.duration,
      start: workTime.start,
      end: workTime.end,
      project: `projects/${workTime.project}`,
      task: `tasks/${workTime.task}`,
      user: workTime.user.uid,
    };

    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/times/${workTime.id}`, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(workTimeDoc), // body data type must match "Content-Type" header
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // console.log(error);
  }
}

export async function _updateWorkingTimesBilling(workTime, resolve) {
  await _updateTimeData(workTime);
  resolve();
}

export async function _deleteTime(time) {
  try {
    await fetch(`${process.env.REACT_APP_DATABASE_URL}/clients/${time.id}/`, {
      method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: 'application/json',
      },
    });
    return;
  } catch (error) {
    // console.log(error);
  }
}
