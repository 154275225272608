import { useEffect, useState } from 'react';
import { getuserWorkingTimes } from '../api/workingTimesCalls';
import { auth } from '../firebase';
import DropdownFilter from './DropdownFilter';
import { timeFilter } from '../utils/filterValues';
import { displayToday } from '../utils/dateUtils';
import dashboardImage from '../images/dashboard-image.png';
import AddTime from '../modals/AddTime';
import LoadingBar from '../components/LoadingBar';
import EditTime from '../modals/EditTime';

import { getClients } from '../api/clientCalls';
import { getProjects } from '../api/projectCalls';
import { getTasks } from '../api/taskCalls';
import { getEmployee } from '../api/employeeCalls';

const date = new Date();
const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
const endDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59, 999);

export default function WorkingTimes() {
  const [workingTimes, setWorkingTime] = useState([]);
  const [currentDate, setCurrentDate] = useState('');
  const [user, setUser] = useState(null);
  const [loadingFinishedProject, setLoadingFinishedProject] = useState(false);
  const [loadingFinishedUser, setLoadingFinishedUser] = useState(false);
  const [editTimeId, setEditTimeId] = useState(null);

  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const date = displayToday();
    setCurrentDate(date);
  }, []);

  useEffect(() => {
    const _user = auth.currentUser;
    if (_user) {
      getuserWorkingTimes(_user.uid, startDate, endDate).then((times) => {
        setWorkingTime(times);
        setLoadingFinishedProject(true);
      });

      getEmployee(_user.uid).then((user) => {
        setUser(user);
        setLoadingFinishedUser(true);
      });

      getClients().then((clients) => {
        setClients(clients);
      });

      getProjects().then((projects) => {
        setProjects(projects);
      });

      getTasks().then((tasks) => {
        setTasks(tasks);
      });
    }
  }, [setWorkingTime, setLoadingFinishedProject, setLoadingFinishedUser, setClients, setProjects, setTasks]);

  async function updateTimes(startDate, endDate) {
    const times = await getuserWorkingTimes(auth.currentUser.uid, startDate, endDate);
    setWorkingTime(times);
  }

  function handleEditClick(time) {
    setEditTimeId(time.id);
  }

  function handleEditCancel() {
    setEditTimeId(null);
  }

  async function handleEditSubmit() {
    await updateTimes(startDate, endDate);
    setEditTimeId(null);
  }
  
  const editWorkTime = workingTimes.find((time) => time.id === editTimeId);
  const workTimeSelected = editWorkTime ? true : false;

  return (
    <div className="static 2xl:w-3/4 w-full">
      {editTimeId ? (
        <div className="absolute w-5/6 h-full z-50 bg-gray-500/50">
          <div className="h-full flex justify-center items-center">
            <EditTime
              worktime={editWorkTime}
              onCancel={handleEditCancel}
              onAdded={handleEditSubmit}
              clients={clients}
              projects={projects}
              tasks={tasks}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="mx-12 mt-20 mb-16 px-4 sm:px-6 lg:px-8">
        {loadingFinishedUser && loadingFinishedProject ? (
          <div>
            <h1 className="text-5xl font-extrabold">{user ? `Hallo ${user.firstname}!` : ''}</h1>
            <h1 className="text-2xl">{currentDate}</h1>
            <div className="grid grid-cols-8 items-end justify-between">
              <img className="col-start-1 col-span-3" src={dashboardImage} alt="co-dex GmbH"></img>
              {/* <span className="font-bold mb-14 col-start-4 col-span-1">Keine Sorge, es geht nicht um Kontrolle.</span> */}

              <div className="col-start-5 ml-4 col-span-4">
                <AddTime
                  onAdded={() => updateTimes(startDate, endDate)}
                  disableFlatPickr={workTimeSelected}
                  clients={clients}
                  projects={projects}
                  tasks={tasks}
                />
              </div>
            </div>
            <div className="sm:flex-auto mt-32">
              <h1 className="font-bold leading-6 text-gray-900 text-3xl">Meine Zeiten</h1>
              <p className="mt-2 text-gray-700">Hier kannst du deine letzten Zeiten ansehen und bearbeiten</p>
            </div>
            <div className="mt-8 flow-root">
              <DropdownFilter title={'Zeitraum'} filter={timeFilter} callback={(val) => updateTimes(val.startDate, val.endDate)} />
            </div>

            {workingTimes.length > 0 ? (
              <div className="mt-8 flow-root bg-white px-4 py-5 shadow rounded-3xl overflow-scroll" style={{ maxHeight: '70vh' }}>
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 sm:pl-0">
                          Datum
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Start
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Ende
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Dauer
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Kunde
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Projekt
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Tätigkeit
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {workingTimes.map((workingTime) => (
                        // // console.log(workingTime),
                        <tr key={workingTime.id}>
                          {/* <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {workingTime.name}
                      </td> */}
                          <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">{workingTime.date}</td>
                          <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">{workingTime.start}</td>
                          <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">{workingTime.end}</td>
                          <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">{workingTime.duration}</td>
                          <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">{workingTime.client}</td>
                          <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">{workingTime.project}</td>
                          <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">{workingTime.task}</td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                            <button className="text-primary hover:text-indigo-900" onClick={() => handleEditClick(workingTime)}>
                              Bearbeiten
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <LoadingBar small />
        )}
      </div>
    </div>
  );
}
