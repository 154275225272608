export default class WorkTime {
    constructor(id, date, start, end, duration, client, project, task, user) {
      this.id = id?? null;
      this.date = date;
      this.start = start; 
      this.end = end;
      this.duration = duration;
      this.client = client;
      this.project = project;
      this.task = task;
      this.user = user;
    }
  }